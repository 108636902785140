import { schemas, useClient, useMutation } from '@weenat/client'
import { languageOptions } from '@weenat/client/dist/core/me'
import { getMetricsPerPhysicalQuantity, getUnits } from '@weenat/client/dist/core/units'
import { useIntl } from '@weenat/wintl'
import useToasts from 'app/src/hooks/useToasts'
import SuperForm from 'app/src/kit/SuperForm'
import UseOnChange from 'app/src/kit/fields/UseOnChange'
import { SelectedOrgs, useToggleFeature } from 'app/state'
import { useUserContext } from 'app/userProvider'
import isNil from 'lodash-es/isNil'
import { useCallback, useMemo, useState } from 'react'
import { styled } from 'styled-components'
import { SelectOrgFields } from '../dashboard/components/DashboardMap/SelectOrg'
import Button from '../kit/Button'
import Icons from '../kit/Icons'
import Text from '../kit/Text'
import SelectField from '../kit/fields/SelectField'
import AccountSection from './AccountSection'

const schema = schemas.me.editMyPreferences

type SchemasValues = Partial<typeof schema.initialValues>

const physicalQuantities = ['RR', 'T', 'FF', 'ECP'] as const

const rainfallMetrics = getMetricsPerPhysicalQuantity('length')
const temperatureMetrics = getMetricsPerPhysicalQuantity('temperature')
const speedMetrics = getMetricsPerPhysicalQuantity('windSpeed')
const electroConductivityMetrics = getMetricsPerPhysicalQuantity('electricalConductivity')

const getFieldNameFromMetric = (metric: string): string => {
  switch (metric) {
    case 'RR':
      return 'rainUnit'
    case 'FF':
      return 'speedUnit'
    case 'T':
      return 'temperatureUnit'
    case 'ECP':
      return 'electroConductivityUnit'
    case 'distance':
      return 'distance'
    default:
      throw TypeError
  }
}

const SubmitButton = styled(Button)`
  display: block;
  margin-left: auto;
`

const EditAppPreferences = () => {
  const { t } = useIntl()
  const client = useClient()
  const { addSuccessToast } = useToasts()

  const { profile } = useUserContext()
  const [{ distance_unit }] = useToggleFeature()

  const [selectedOrgs] = useState<SelectedOrgs>({
    organizationId: isNil(profile?.favoriteOrgId) ? null : profile?.favoriteOrgId,
    childOrganizationId: null
  })

  const [editMyProfile, editMyProfileStatus] = useMutation(client.me.updateMyProfile(), {
    onSuccess: () => {
      addSuccessToast(t('models.profile.actions.edit_success'))
    }
  })

  const getFieldLabelFromMetric = (metric: string): string => {
    if (metric === 'FF') return t(`metrics.speed`, { capitalize: true })
    return t(`metrics.${metric}`, { capitalize: true })
  }

  const sharedProps = {
    isDisabled: editMyProfileStatus.isPending,
    errorState: editMyProfileStatus.error
  }

  const physicalQuantitiesToDisplay = distance_unit
    ? [...physicalQuantities, 'distance']
    : physicalQuantities

  const initialValues: SchemasValues = useMemo(() => {
    return {
      language: profile?.language || 'fr',
      favoriteOrgId: isNil(profile?.favoriteOrgId) ? undefined : profile?.favoriteOrgId,
      rainUnit: profile?.units['RR'],
      temperatureUnit: profile?.units['T'],
      speedUnit: profile?.units['FF'],
      electroConductivityUnit: profile?.units['ECP'],
      distance: profile?.units['DISTANCE']
    }
  }, [profile])

  const handleSubmit = useCallback(
    (values: SchemasValues) => {
      const rainUnits = rainfallMetrics.reduce((o, key) => ({ ...o, [key]: values.rainUnit }), {})
      const speedUnits = speedMetrics.reduce((o, key) => ({ ...o, [key]: values.speedUnit }), {})
      const temperatureUnits = temperatureMetrics.reduce(
        (o, key) => ({ ...o, [key]: values.temperatureUnit }),
        {}
      )
      const electroConductivityUnits = electroConductivityMetrics.reduce(
        (o, key) => ({ ...o, [key]: values.electroConductivityUnit }),
        {}
      )

      const payload = {
        favoriteOrgId: values.favoriteOrgId,
        units: {
          ...(profile?.units || {}),
          ...rainUnits,
          ...speedUnits,
          ...temperatureUnits,
          ...electroConductivityUnits,
          DISTANCE: values.distance
        },
        language: values.language
      }
      editMyProfile(payload)
    },
    [editMyProfile, profile?.units]
  )

  const handleFormChange = useCallback(() => {
    editMyProfileStatus.reset()
  }, [editMyProfileStatus])

  return (
    <Box $pb={32}>
      <SuperForm
        schema={schema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        <AccountSection title={t('models.profile.fields.favoriteOrg.label', { capitalize: true })}>
          <SelectOrgFields orgFieldName='favoriteOrgId' withoutChild selectedOrgs={selectedOrgs} />
        </AccountSection>

        <AccountSection title={t('models.profile.fields.language.label', { capitalize: true })}>
          <SelectField
            label={t('models.profile.fields.language.label', { capitalize: true })}
            name='language'
            options={languageOptions}
            {...sharedProps}
          />
        </AccountSection>

        <AccountSection title={t('models.profile.fields.units.label', { capitalize: true })}>
          {physicalQuantitiesToDisplay.map((metric) => (
            <SelectField
              key={metric}
              name={getFieldNameFromMetric(metric)}
              label={getFieldLabelFromMetric(metric)}
              options={Object.entries(getUnits(metric)).map(([value, unit]) => ({
                value: parseInt(value, 10),
                label: t(`units.${unit}`, { capitalize: true })
              }))}
              {...sharedProps}
            />
          ))}
          <Flex $gap='md'>
            <Icons.InfoCircle $p='sm' $size='md' $color={'grayscale.700'} />
            <Text $color={'grayscale.700'}>{t('me_menu.export_disclaimer')}</Text>
          </Flex>
        </AccountSection>
        <UseOnChange onChange={handleFormChange} />
        <SubmitButton type='submit' state={editMyProfileStatus}>
          {t('actions.confirm')}
        </SubmitButton>
      </SuperForm>
    </Box>
  )
}

export default EditAppPreferences
