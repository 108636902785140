import { useIntl } from '@weenat/wintl'
import { isEmpty } from 'lodash-es'
import { transparentize } from 'polished'
import { ReactNode } from 'react'
import { useTheme } from 'styled-components'
import useDisclosure from '../hooks/useDisclosure'
import Button from './Button'
import Modal from './Modal'
import Text from './Text'

interface ListSelectionPreviewProps<T> {
  items: T[]
  renderItem: (item: T) => ReactNode
  emptyMessage?: string
}

/**
 * Component to render when there is a list to preview inside a multi select list
 * Such as alert targets or plot selection on the export Menu
 */
function ListSelectionPreview<T>({
  emptyMessage,
  items,
  renderItem
}: ListSelectionPreviewProps<T>) {
  const { t } = useIntl()
  const { colors } = useTheme()

  const allItemsModal = useDisclosure()

  const title = t('lists.filters_selected')

  return (
    <>
      <Flex
        $flexDirection='column'
        $gap={'md'}
        $borderWidth={1}
        $borderColor={'grayscale.300'}
        $borderRadius={'md'}
        $p={'md'}
        $backgroundColor={transparentize(0.95, colors.grayscale.black)}
      >
        <Text $fontWeight='medium'>{title}</Text>
        {isEmpty(items) ? (
          <Flex
            $p='lg'
            $mx='lg'
            $flexDirection='column'
            $alignItems='center'
            $justifyContent='center'
            $minHeight={48}
          >
            <Text $textAlign='center'>{emptyMessage ?? t('lists.no_items')}</Text>
          </Flex>
        ) : (
          <Flex $alignItems='center' $justifyContent='space-between' $gap='md'>
            <Flex $alignItems='center' $flexWrap='nowrap' $gap='md' $minHeight={48}>
              {items.slice(0, 2).map(renderItem)}
            </Flex>
            {items.length > 2 ? (
              <Button variant='small' onPress={allItemsModal.open}>
                +{items.length - 2}
              </Button>
            ) : null}
          </Flex>
        )}
      </Flex>
      <Modal
        isOpen={allItemsModal.isOpen}
        close={allItemsModal.close}
        width={480}
        height={600}
        title={title}
      >
        <Flex $flexDirection='column' $gap={'md'}>
          {items.map(renderItem)}
        </Flex>
      </Modal>
    </>
  )
}

export default ListSelectionPreview
