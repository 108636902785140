import { useChartCtx } from '@weenat/client/dist/core/charts'
import { metricsWithGradient } from '@weenat/client/dist/core/charts/charts'
import { DataPoint } from '@weenat/client/dist/core/charts/d3-charts.type'
import { getColorScalePerMetric, getYDomainPerMetric } from '@weenat/client/dist/core/measurements'
import { AllKey } from '@weenat/client/dist/enums/MetricIds'
import TimeSteps from '@weenat/client/dist/enums/TimeSteps'
import { fromColorPathToColor } from '@weenat/theme'
import * as d3 from 'd3'
import { isNil } from 'lodash-es'
import { SVGProps, memo, useId, useMemo } from 'react'

const stops = [0, 0.25, 0.5, 0.75, 1]
const defaultColorScale = () => () => 'black'
const defaultScale = d3.scaleLinear()
const defaultDenormalize = (x: number | null) => x

interface AreaProps extends Omit<SVGProps<SVGPathElement>, 'fillOpacity' | 'scale'> {
  data: DataPoint[]
  y0Data?: DataPoint[]
  strokeWidth?: number
  opacity?: number
  color?: string
  metric?: AllKey
  timeStep?: TimeSteps
  denormalize?: (val: number) => number
  scale?: d3.ScaleLinear<number, number>
}

const Area = ({
  data,
  color,
  opacity = 0.2,
  metric,
  y0Data,
  timeStep = TimeSteps.perHours,
  denormalize = defaultDenormalize,
  scale = defaultScale,
  strokeWidth = 0,
  ...pathProps
}: AreaProps) => {
  const id = useId()
  const linearGradientId = `gradient_${id}`

  const useGradient = !isNil(metric) && metricsWithGradient.includes(metric) && isNil(color)

  const finalColor = useGradient
    ? `url(#${linearGradientId})`
    : color !== undefined
      ? color
      : !isNil(metric)
        ? fromColorPathToColor(`metrics.${metric}.500`)
        : 'black'

  const colors = useMemo(() => {
    const colorScale = !isNil(metric)
      ? getColorScalePerMetric(metric)(getYDomainPerMetric({ metric, timeStep }))
      : defaultColorScale()

    return stops.map((quantile) =>
      colorScale(denormalize(d3.quantileSorted(scale.domain(), quantile)!))
    )
  }, [denormalize, metric, scale, timeStep])

  const { xScale, yScale, y0: y0FromCTX } = useChartCtx()

  let y0 = y0FromCTX

  if (!isNil(y0Data)) {
    y0 = (d) =>
      yScale(scale(y0Data.find((y0D) => d.date.getTime() === y0D.date.getTime())?.value ?? 0))
  }

  const line =
    strokeWidth > 0
      ? d3
          .line<DataPoint>()
          .x((d) => xScale(d.date))
          .defined((d) => d.value !== null)
          .y((d) => yScale(scale(d.value!)))
      : undefined

  const area = d3
    .area<DataPoint>()
    .x((d) => xScale(d.date))
    .y1((d) => yScale(scale(d.value!)))
    .y0(y0)
    .defined((d) => d.value !== null)

  const areaPath = area(data)
  const linePath = !isNil(line) ? line(data) : null

  if (areaPath === null) {
    return null
  }

  return (
    <>
      {useGradient && (
        <defs>
          <linearGradient
            id={linearGradientId}
            x1='0%'
            y1={`100%`}
            x2='0%'
            y2={`0%`}
            gradientUnits='userSpaceOnUse'
          >
            {colors.map((stopColor, idx) => (
              <stop
                key={`${linearGradientId}-${stopColor}`}
                offset={`${stops[idx] * 100}%`}
                stopColor={stopColor}
              />
            ))}
          </linearGradient>
        </defs>
      )}
      <path fill={finalColor} fillOpacity={opacity} d={areaPath} {...pathProps} />
      {!isNil(linePath) ? (
        <path fill='none' stroke={finalColor} strokeWidth={strokeWidth} d={linePath} />
      ) : null}
    </>
  )
}

export default memo(Area)
