import { useClient, useQuery } from '@weenat/client'
import { canUser, ops } from '@weenat/client/dist/core/access'
import paths from '@weenat/client/dist/core/appPaths'
import { InvitationStatus } from '@weenat/client/dist/enums'
import { useIntl } from '@weenat/wintl'
import { Slot, usePathname } from 'app/routx-router'
import Logout from 'app/src/authentication/components/Logout'
import Icons from 'app/src/kit/Icons'
import Panel from 'app/src/kit/panels/Panel'
import PanelLinkSection, {
  IPanelLinkSection,
  PanelLinkSectionItem,
  renderPanelLinkSectionItem
} from 'app/src/kit/panels/PanelLinkSection'
import PanelSection from 'app/src/kit/panels/PanelSection'
import Scrollable from 'app/src/kit/Scrollable'
import Text from 'app/src/kit/Text'
import AuthenticatedLayout from 'app/src/layouts/AuthenticatedLayout'
import FocusLayout from 'app/src/layouts/FocusLayout'
import MenuLayout from 'app/src/layouts/MenuLayout'
import ProtectedRoute from 'app/src/layouts/ProtectedRoute'
import ProfileCard from 'app/src/me/ProfileCard'
import { useLoginAs, useToggleFeature, useToken } from 'app/state'
import { useUserContext } from 'app/userProvider'
import { getVersion } from 'app/utils'
import { getWintlVersion } from 'app/utils/getVersion'
import { isNil } from 'lodash-es'
import { useMemo } from 'react'
import { styled } from 'styled-components'

const ScrollablePanelSection = styled(PanelSection)`
  flex: 1;

  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-gutter: stable both-edges;

  &:hover {
    overflow-y: scroll;
  }
`

const Content = styled(Scrollable)`
  flex: 1;
  padding: ${({ theme }) => theme.spacings.lg}px;

  scroll-behavior: smooth;
  scrollbar-gutter: stable both-edges;
`

const MeLayout = () => {
  const { t } = useIntl()
  const client = useClient()
  const [{ redesign }] = useToggleFeature()
  const { me } = useUserContext()
  const [loginAs] = useLoginAs()

  const receivedInvitations = useQuery(
    client.invitations.received.getMany({ limit: 1, status: InvitationStatus.pending })
  ).data

  const canViewAdminTools = canUser(ops.view, 'debugMode', { account: me }) || !isNil(loginAs)

  const title = t('titles.user_settings')

  const menuHeader = (
    <Box $minHeight='64px' $p='lg'>
      <ProfileCard me={me} />
    </Box>
  )

  // trick to force refresh on language change
  // adding user profile language in memo deps doesn't work
  const preferencesTitle = t('me_menu.app_preferences_title')
  const menuSections: IPanelLinkSection[] = useMemo(() => {
    const sections: IPanelLinkSection[] = [
      {
        id: 'preferences-section',
        title: t('titles.preferences'),
        items: []
      }
    ]

    sections[0].items.push(
      {
        id: 'preferences',
        LeftIcon: Icons.DiscoverTune,
        to: '/me/preferences',
        label: preferencesTitle,
        description: t('me_menu.preferences_subtitle'),
        matchExactPath: true
      },
      {
        id: 'notifications_preferences',
        LeftIcon: Icons.Notifications,
        to: '/me/preferences/notifications',
        label: t('breadcrumbs./me/preferences/notifications'),
        description: t('models.notificationPreference.description'),
        matchExactPath: true
      }
    )

    const myAccountSections: PanelLinkSectionItem[] = [
      {
        id: 'account-settings',
        LeftIcon: Icons.AccountSettings,
        to: '/me/account',
        description: t('me_menu.security_privacy_subtitle'),
        label: t('me_menu.security_privacy_title'),
        matchExactPath: true
      },
      {
        id: 'authorized-devices',
        LeftIcon: Icons.AuthorizedDevice,
        to: '/me/mobile-devices',
        description: t('me_menu.devices_subtitle'),
        label: t('titles.mobile_devices'),
        matchExactPath: true
      },
      {
        id: 'external-connections',
        to: paths.me.externalConnections,
        label: t('titles.external_connection'),
        description: t('titles.external_connection_baseline'),
        LeftIcon: Icons.ExternalConnexions,
        matchExactPath: false
      }
    ]
    if (redesign) {
      myAccountSections.push({
        id: 'api',
        to: paths.me.api,
        description: t('breadcrumbs./me/api'),
        label: t('breadcrumbs./me/api'),
        LeftIcon: Icons.Token,
        matchExactPath: false
      })
    }
    sections.push(
      {
        id: 'my-account-section',
        title: t('me_menu.my_account_group'),
        items: myAccountSections
      },
      {
        id: 'contact-section',
        title: t('me_menu.contact_group'),
        items: [
          {
            id: 'contact-information',
            LeftIcon: Icons.Phone,
            label: t('breadcrumbs./me/contact-information'),
            to: '/me/contact-information',
            description: t('me_menu.contact_infos_subtitle'),
            matchExactPath: true
          },
          {
            id: 'invitations',
            LeftIcon: Icons.Inbox,
            label: t('breadcrumbs./me/invitations'),
            to: '/me/invitations',
            indicator: receivedInvitations?.count ?? 0,
            matchExactPath: false
          }
        ]
      }
    )

    if (canViewAdminTools) {
      sections.push({
        id: 'admin-tools',
        title: t('me_menu.admin_tool_group'),
        items: [
          {
            id: 'debug',
            to: paths.me.debug,
            label: t('titles.debug'),
            LeftIcon: Icons.Bug,
            matchExactPath: true
          },
          {
            id: 'feature_toggle',
            to: paths.me.featureToggle,
            label: t('titles.toggle_feature'),
            LeftIcon: Icons.New,
            matchExactPath: true
          },
          {
            id: 'cluster_settings',
            to: paths.me.clusterSettings,
            label: t('titles.cluster_settings'),
            LeftIcon: Icons.Cluster,
            matchExactPath: true
          }
        ]
      })
    }

    return sections
  }, [t, preferencesTitle, redesign, receivedInvitations?.count, canViewAdminTools])

  const menuFooter = useMemo(
    () => (
      <Flex $flexDirection='column' $alignItems='center' $minHeight='96px' $p='lg' $gap='md'>
        <Logout />
        <Text $textAlign='center'>{`${t('titles.version')}: ${getVersion()}`}</Text>
      </Flex>
    ),
    [t]
  )

  return redesign ? (
    <Flex $width={'100%'} $height={'100%'}>
      <Panel docking='left' width={320}>
        <PanelSection>
          <ProfileCard me={me} />
        </PanelSection>
        <ScrollablePanelSection>
          <div>
            {menuSections.map((section) => (
              <PanelLinkSection key={section.id} title={section.title}>
                {section.items.map((item) => renderPanelLinkSectionItem(item))}
              </PanelLinkSection>
            ))}
          </div>
        </ScrollablePanelSection>
        <PanelSection $alignItems='center' $minHeight='96px' $gap='md'>
          <Logout />
          <Text $textAlign='center'>{`${t('titles.version')}: ${getVersion()}`}</Text>
          {canViewAdminTools && <Text $textAlign='center'>{`Wintl: ${getWintlVersion()}`}</Text>}
        </PanelSection>
      </Panel>
      <MenuLayout>
        <Content>
          <Slot />
        </Content>
      </MenuLayout>
    </Flex>
  ) : (
    <FocusLayout
      title={title}
      titleTemplate={`${title} / %s - Weenat`}
      defaultTitle={title}
      menuHeader={menuHeader}
      menuFooter={menuFooter}
      menuSections={menuSections}
    >
      <Scrollable>
        <Slot />
      </Scrollable>
    </FocusLayout>
  )
}

const UnLoggedLayout = () => {
  return <Slot />
}

const LoggedLayout: FC = () => {
  return (
    <ProtectedRoute>
      <AuthenticatedLayout>
        <MeLayout />
      </AuthenticatedLayout>
    </ProtectedRoute>
  )
}

const Layout = () => {
  const [token] = useToken()
  const pathname = usePathname()
  return !isNil(token) ? (
    <LoggedLayout />
  ) : pathname.includes('invitations/accept') ? (
    <UnLoggedLayout />
  ) : null
}

export const Component = Layout
export default Layout
