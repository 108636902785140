import { IconProps } from '../Icon'
import { Irrelis } from './generic-icons'
import GenericIcon from './GenericIcon'
import {
  AcUnit,
  Capacitive,
  CloudCoverage,
  Etp,
  EtpSum,
  Humidity,
  HydricPotential,
  Irrigation,
  IrrigationSum,
  LeafWetness,
  PhotosyntheticActiveRadiation,
  Rainfalls,
  RainfallsSum,
  SoilWaterContent,
  SolarFlares,
  Temperature,
  TemperatureDry,
  TemperatureGround,
  TemperatureWet,
  VaporPressureDeficit,
  WaterElectroConductivity,
  Wind,
  WindDirection
} from './metric-icons'

const MetricIcons = {
  CLDC: (props: IconProps) => <GenericIcon svg={<CloudCoverage />} {...props} />,
  COLD_T_HOUR: (props: IconProps) => <GenericIcon svg={<AcUnit />} {...props} />,
  DD: (props: IconProps) => <GenericIcon svg={<WindDirection />} {...props} />,
  DXY: (props: IconProps) => <GenericIcon svg={<WindDirection />} {...props} />,
  etp: (props: IconProps) => <GenericIcon svg={<Etp />} {...props} />,
  FF: (props: IconProps) => <GenericIcon svg={<Wind />} {...props} />,
  FXY: (props: IconProps) => <GenericIcon svg={<Wind />} {...props} />,
  HDEF: (props: IconProps) => <GenericIcon svg={<Irrelis />} {...props} />,
  heatAccumulation: (props: IconProps) => <GenericIcon svg={<Temperature />} {...props} />,
  RRAccumulation: (props: IconProps) => <GenericIcon svg={<Rainfalls />} {...props} />,
  SSI_HOURSAccumulation: (props: IconProps) => <GenericIcon svg={<SolarFlares />} {...props} />,
  HPOT: (props: IconProps) => <GenericIcon svg={<HydricPotential />} {...props} />,
  irrigation: (props: IconProps) => <GenericIcon svg={<Irrigation />} {...props} />,
  LW_D: (props: IconProps) => <GenericIcon svg={<LeafWetness />} {...props} />,
  LW_V: (props: IconProps) => <GenericIcon svg={<LeafWetness />} {...props} />,
  RR: (props: IconProps) => <GenericIcon svg={<Rainfalls />} {...props} />,
  IRR: (props: IconProps) => <GenericIcon svg={<Irrigation />} {...props} />,
  SSI_HOURS: (props: IconProps) => <GenericIcon svg={<SolarFlares />} {...props} />,
  SSI: (props: IconProps) => <GenericIcon svg={<SolarFlares />} {...props} />,
  SSI_MIN: (props: IconProps) => <GenericIcon svg={<SolarFlares />} {...props} />,
  SSI_MAX: (props: IconProps) => <GenericIcon svg={<SolarFlares />} {...props} />,
  T_CAPA: (props: IconProps) => <GenericIcon svg={<TemperatureGround />} {...props} />,
  T_CAL: (props: IconProps) => <GenericIcon svg={<TemperatureGround />} {...props} />,
  T_DEW: (props: IconProps) => <GenericIcon svg={<Temperature />} {...props} />,
  T_DRY: (props: IconProps) => <GenericIcon svg={<TemperatureDry />} {...props} />,
  T_SOIL: (props: IconProps) => <GenericIcon svg={<TemperatureGround />} {...props} />,
  T_WET: (props: IconProps) => <GenericIcon svg={<TemperatureWet />} {...props} />,
  T: (props: IconProps) => <GenericIcon svg={<Temperature />} {...props} />,
  U_CAPA: (props: IconProps) => <GenericIcon svg={<Capacitive />} {...props} />,
  U: (props: IconProps) => <GenericIcon svg={<Humidity />} {...props} />,
  IRR_ACCUMULATION: (props: IconProps) => <GenericIcon svg={<IrrigationSum />} {...props} />,
  ETP_ACCUMULATION: (props: IconProps) => <GenericIcon svg={<EtpSum />} {...props} />,
  RR_ACCUMULATION: (props: IconProps) => <GenericIcon svg={<RainfallsSum />} {...props} />,
  PPFD: (props: IconProps) => <GenericIcon svg={<PhotosyntheticActiveRadiation />} {...props} />,
  PPFD_MIN: (props: IconProps) => (
    <GenericIcon svg={<PhotosyntheticActiveRadiation />} {...props} />
  ),
  PPFD_MAX: (props: IconProps) => (
    <GenericIcon svg={<PhotosyntheticActiveRadiation />} {...props} />
  ),
  SWC: (props: IconProps) => <GenericIcon svg={<SoilWaterContent />} {...props} />,
  ECP: (props: IconProps) => <GenericIcon svg={<WaterElectroConductivity />} {...props} />,
  VPD: (props: IconProps) => <GenericIcon svg={<VaporPressureDeficit />} {...props} />
}

export default MetricIcons
