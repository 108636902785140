import { fromWindAngleToDirection } from '@weenat/client/dist/core/metrics'
import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import { RestrictionReason } from 'app/src/hooks/useIsAppAccessRestricted'
import Icons from 'app/src/kit/Icons'
import { LockedCarouselValueModal } from 'app/src/kit/LockedFeat'
import Text from 'app/src/kit/Text'
import WindDirectionIcon from 'app/src/kit/icons/WindDirectionIcon'
import { useToggleFeature } from 'app/state'
import isNil from 'lodash-es/isNil'
import { memo } from 'react'
import { useTheme } from 'styled-components'
import { METRIC_COLUMN_WIDTH } from '../PlotOrStationList'
import useUniverseBackgroundMapContext from '../universes/useUniverseBackgroundMapContext'
import { PlotListItemsVariants } from './PlotListItemMetricSummary'
import PlotListItemValue, { PlotSummaryValue } from './PlotListItemValue'
import PlotListItemValueName from './PlotListItemValueName'
import PlotListItemValuesContainer from './PlotListItemValuesContainer'

const metric = 'FF'

interface WindPlotListItemValuesProps {
  averageWindNow: PlotSummaryValue
  windGustNow: PlotSummaryValue
  windDirectionNow: PlotSummaryValue
  isVirtualDevice: boolean
  isRestricted: boolean
  reason: RestrictionReason
  variant: PlotListItemsVariants
}

/** PlotListItem values for Winds */
const WindPlotListItemValues: FC<WindPlotListItemValuesProps> = ({
  averageWindNow,
  windGustNow,
  windDirectionNow,
  isRestricted: isRestrictedFromProps,
  reason,
  isVirtualDevice,
  variant
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()
  const { universeAggregate } = useUniverseBackgroundMapContext()
  const [{ wr_forecasts }] = useToggleFeature()

  const isRestricted = isRestrictedFromProps && !isVirtualDevice
  const color = isRestricted ? 'grayscale.300' : `metrics.${metric}.500`
  const isListVariant = variant === 'list'

  const borderStyle = `2px solid ${universeAggregate === 'real-time-DD' ? color : colors.grayscale.white}`
  const selectedStyle: React.CSSProperties = {
    border: `${isListVariant ? '0' : borderStyle}`,
    borderRadius: 10,
    padding: `${isListVariant ? '0' : 'inherit'}`,
    width: `${isListVariant ? METRIC_COLUMN_WIDTH + 'px' : ''}`,
    justifyContent: `${isListVariant ? 'flex-end' : 'flex-start'}`,
    alignItems: `${isListVariant ? 'flex-end' : 'flex-start'}`
  } as const

  const numberWindDirection = !isNil(windDirectionNow)
    ? typeof windDirectionNow === 'string'
      ? parseInt(windDirectionNow, 10)
      : windDirectionNow
    : null

  const { close, isOpen, open } = useDisclosure()

  return (
    <PlotListItemValuesContainer $isRestricted={isRestricted}>
      <PlotListItemValue
        isMainValue
        value={averageWindNow}
        name={t('wind.average')}
        metric={metric}
        selected={universeAggregate === 'real-time-FF'}
        isRestricted={isRestricted && !isVirtualDevice}
        reason={reason}
        variant={variant}
      />
      <PlotListItemValue
        value={windGustNow}
        name={t('wind.gust')}
        metric={metric}
        selected={universeAggregate === 'real-time-FXY'}
        isRestricted={isRestricted && !isVirtualDevice}
        reason={reason}
        variant={variant}
      />

      <Flex
        $flexDirection='column'
        $justifyContent={isRestricted ? 'space-between' : undefined}
        $alignItems='flex-start'
        $flex={1}
        style={selectedStyle}
        $p='md'
        onClick={
          isRestricted
            ? (e) => {
                e.preventDefault()
                e.stopPropagation()
                open()
              }
            : undefined
        }
      >
        {variant !== 'list' ? (
          <PlotListItemValueName name={t('wind.direction')} color={color} />
        ) : null}
        {isRestricted ? (
          <>
            <Icons.Lock $size='lg' $color={'grayscale.700'} />
            <LockedCarouselValueModal close={close} isOpen={isOpen} reason={reason} />
          </>
        ) : variant !== 'list' ? (
          !isNil(numberWindDirection) ? (
            <Flex $alignItems='center' $pt='sm' $gap={'sm'}>
              <WindDirectionIcon
                windOrigin={numberWindDirection}
                $backgroundColor={color}
                $color={'grayscale.white'}
                $rounded
                $p={wr_forecasts ? 'sm' : 'md'}
                $size={wr_forecasts ? 'md' : 'xs'}
              />
              <Text $fontSize='rg' $fontWeight='regular'>
                {t(`windDirections.${fromWindAngleToDirection(numberWindDirection)}`)}
              </Text>
            </Flex>
          ) : null
        ) : !isNil(numberWindDirection) ? (
          <Text $fontSize='rg' $fontWeight='regular'>
            {t(`windDirections.${fromWindAngleToDirection(numberWindDirection)}`)}
          </Text>
        ) : (
          <Text $fontSize='rg' $fontWeight='regular'>
            -
          </Text>
        )}
      </Flex>
    </PlotListItemValuesContainer>
  )
}

export default memo(WindPlotListItemValues)
