import { useIntl } from '@weenat/wintl'
import Card from 'app/src/kit/Card'
import EmailLink from 'app/src/kit/EmailLink'
import { IconProps } from 'app/src/kit/Icon'
import Icons from 'app/src/kit/Icons'
import PhoneLink from 'app/src/kit/PhoneLink'
import Text from 'app/src/kit/Text'
import { memo } from 'react'

export const ContactDetailsLine: FC<{ Icon: FC<IconProps> }> = ({ Icon, children }) => {
  return (
    <Flex $alignItems='center' $py='lg' $gap='lg'>
      <Box>
        <Icon $size='lg' />
      </Box>
      {children}
    </Flex>
  )
}

export const CustomerSupportContactDetails = () => {
  const { t } = useIntl()

  const color = 'primary.500'

  return (
    <>
      <ContactDetailsLine Icon={Icons.Mail}>
        <EmailLink email={t('weenat.support.email')}>
          <Text $fontWeight='bold' $color={color}>
            {t('weenat.support.email')}
          </Text>
        </EmailLink>
      </ContactDetailsLine>
      <ContactDetailsLine Icon={Icons.Phone}>
        <PhoneLink phoneNumber={t('weenat.support.phoneNumber1')}>
          <Text $fontWeight='bold' $color={color}>
            {t('weenat.support.phoneNumber1')}
          </Text>
        </PhoneLink>
      </ContactDetailsLine>
      <ContactDetailsLine Icon={Icons.Clock}>
        <Text>{t('help.hours')}</Text>
      </ContactDetailsLine>
    </>
  )
}

const CustomerSupportSection = () => {
  const { t } = useIntl()

  return (
    <Box $py='lg'>
      <Card>
        <Text $fontWeight='bold'>{t('help.support')}</Text>
        <CustomerSupportContactDetails />
      </Card>
    </Box>
  )
}

export default memo(CustomerSupportSection)
