import { fromWindAngleToDirection } from '@weenat/client/dist/core/metrics'
import { MetricSummaryValue as SummaryValue } from '@weenat/client/dist/core/metrics/useMetricSummary'
import { isMetric } from '@weenat/client/dist/enums/MetricIds'
import { useConvertedValue } from '@weenat/client/dist/hooks'
import { DeviceAvailableMeasure } from '@weenat/client/dist/resources/devices'
import { PlotAvailableMeasure } from '@weenat/client/dist/resources/plots'
import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import { RestrictionReason } from 'app/src/hooks/useIsAppAccessRestricted'
import DewRiskInfo from 'app/src/kit/DewRiskInfo'
import Icons from 'app/src/kit/Icons'
import { LockedCarouselValueModal } from 'app/src/kit/LockedFeat'
import Text from 'app/src/kit/Text'
import WindDirectionIcon from 'app/src/kit/icons/WindDirectionIcon'
import { useToggleFeature } from 'app/state'
import isNil from 'lodash-es/isNil'
import moment from 'moment-timezone'

interface MetricSummaryValueProps {
  metric: DeviceAvailableMeasure | PlotAvailableMeasure
  isShrunk?: boolean
  isMainValue?: boolean
  summaryValue: SummaryValue
  isRestricted?: boolean
  reason?: RestrictionReason
}

const MetricSummaryValue: FC<MetricSummaryValueProps> = ({
  metric,
  isShrunk = false,
  isMainValue,
  summaryValue,
  isRestricted,
  reason
}) => {
  const { t } = useIntl()
  const { getUnit, convertValue } = useConvertedValue()
  const [{ wr_forecasts }] = useToggleFeature()

  const {
    aggregationId,
    fromForecasts,
    hasDewRisk,
    label,
    renderValue,
    timestamp,
    value,
    aggregationLabel
  } = summaryValue

  const nullValueColor = isNil(value) ? 'grayscale.300' : undefined
  const metricColor = isRestricted ? 'grayscale.300' : (`metrics.${metric}.500` as const)
  const unit = getUnit(metric)

  const valueSize = isMainValue ? 'xl' : 'rg'
  const valueWeight = isMainValue ? 'bold' : 'regular'

  const isCurrentTemperature = metric === 'T' && aggregationId === 'latest'
  const { close, isOpen, open } = useDisclosure()

  return (
    <Box
      key={`${aggregationId}-${metric}`}
      onClick={
        isRestricted
          ? (e) => {
              e.stopPropagation()
              e.preventDefault()
              open()
            }
          : undefined
      }
    >
      <Flex $mb='md'>
        <Text $lineHeight='none' $fontSize='sm' $fontWeight='regular' $color={metricColor}>
          {label}
        </Text>
      </Flex>
      <Flex>
        {isRestricted ? (
          <Flex $alignItems='flex-end'>
            <Icons.Lock $color={'grayscale.300'} $size='md' />
            <Text $lineHeight='sm' $fontSize='sm' $color={'grayscale.300'}>
              {t(`units.${unit}_abbr`)}
            </Text>
          </Flex>
        ) : renderValue ? (
          renderValue(value)
        ) : aggregationId === 'DD' ? (
          <>
            {!isNil(value) ? (
              <Flex>
                <WindDirectionIcon
                  windOrigin={value}
                  $size={wr_forecasts ? 'rg' : 'sm'}
                  $rounded={false}
                />
                <Text $fontSize='sm'>{t(`windDirections.${fromWindAngleToDirection(value)}`)}</Text>
              </Flex>
            ) : (
              <Text $lineHeight='xs' $color={'grayscale.300'}>
                -
              </Text>
            )}
          </>
        ) : isCurrentTemperature ? (
          <>
            <Box>
              <Flex>
                <Text
                  $fontSize={valueSize}
                  $fontWeight={valueWeight}
                  $lineHeight='xs'
                  $color={nullValueColor}
                  $fontVariantNumeric='tabular-nums'
                >
                  {!isNil(value)
                    ? convertValue({
                        value,
                        metric: isMetric(aggregationId) ? aggregationId : metric,
                        displayUnit: !isMainValue,
                        abbreviatedUnit: true
                      })
                    : '-'}
                </Text>
                {isMainValue && t(`units.${unit}_abbr`).length !== 0 ? (
                  <Box $ml={1}>
                    <Text $fontSize='sm' $color={nullValueColor}>
                      {t(`units.${unit}_abbr`)}
                    </Text>
                  </Box>
                ) : null}
              </Flex>
              {hasDewRisk ? <DewRiskInfo showLabel /> : null}
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Text
                $fontSize={valueSize}
                $fontWeight={valueWeight}
                $lineHeight='xs'
                $color={nullValueColor}
                $fontVariantNumeric='tabular-nums'
              >
                {!isNil(value)
                  ? convertValue({
                      value,
                      metric: isMetric(aggregationId) ? aggregationId : metric,
                      displayUnit: !isMainValue,
                      abbreviatedUnit: true
                    })
                  : '-'}
              </Text>
              {!isMainValue && !isNil(timestamp) && !isShrunk && (
                <Text $fontSize='xs' $fontStyle='italic' $color={'grayscale.black'}>
                  {t(fromForecasts ? 'summaries.expected_at' : 'summaries.measured_at', {
                    // eslint-disable-next-line import/no-named-as-default-member
                    time: moment.unix(parseInt(timestamp, 10)).format(t('formats.time'))
                  })}
                </Text>
              )}
              {!isMainValue && !isNil(aggregationLabel) && !isShrunk && (
                <Text $fontSize='xs' $fontStyle='italic' $color={'grayscale.800'}>
                  {aggregationLabel}
                </Text>
              )}
            </Box>

            {isMainValue && t(`units.${unit}_abbr`).length !== 0 && (
              <Box $ml={1}>
                <Text $fontSize='sm' $color={nullValueColor}>
                  {t(`units.${unit}_abbr`)}
                </Text>
              </Box>
            )}
          </>
        )}
      </Flex>
      {isRestricted && (
        <LockedCarouselValueModal close={close} isOpen={isOpen} reason={reason ?? 'freemium'} />
      )}
    </Box>
  )
}

export default MetricSummaryValue
