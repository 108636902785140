import Text, { TextProps } from '../Text'

interface PanelSectionTitleProps extends TextProps {}

const PanelSectionTitle: FC<PanelSectionTitleProps> = ({ children, className, ...props }) => {
  return (
    <Text
      $fontSize='sm'
      $fontWeight='medium'
      $color={'grayscale.700'}
      $lineHeight='none'
      className={className}
      {...props}
    >
      {children}
    </Text>
  )
}

export default PanelSectionTitle
