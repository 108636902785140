import { useIntl } from '@weenat/wintl'
import isNil from 'lodash-es/isNil'
import { styled, useTheme } from 'styled-components'
import TextTooltip from '../kit/tooltips/TextTooltip'
import MapBottomDefaultControls from './MapBottomDefaultControls'
import MapControl from './MapControl'
import MapControlsContainer from './MapControlsContainer'
import {
  AllMapsControlsType,
  MapControlOptions,
  MapsControlsOptions,
  MapsDrawingControlsType
} from './utils/defaults'

const Container = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${(p) => p.theme.spacings.md}px;
  gap: ${(p) => p.theme.spacings.md}px;
`

export interface MapBottomControlsProps {
  controls: AllMapsControlsType[]
  controlsOptions: MapsControlsOptions
}

const MapBottomControls: FC<MapBottomControlsProps> = ({ controls = [], controlsOptions = {} }) => {
  const { t } = useIntl()

  const shouldDisplayDrawingTools = controls.includes('DRAWING')

  const drawingOptions = controlsOptions['DRAWING'] as Partial<
    Record<MapsDrawingControlsType, MapControlOptions>
  >

  const executeDrawingOptionPress = (type: 'FULLSCREEN' | 'GRAB' | 'POLYGON' | 'TRASH') => () => {
    if (!isNil(drawingOptions) && !drawingOptions[type]?.disabled) {
      drawingOptions[type]?.onPress?.()
    }
  }

  const isDrawPolygonDisabled = !isNil(drawingOptions) && drawingOptions['POLYGON']?.disabled

  const { spacings } = useTheme()
  const mapControlStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacings.md
  }

  return (
    <Container>
      <MapBottomDefaultControls controlsOptions={controlsOptions} />
      {shouldDisplayDrawingTools ? (
        <Flex $gap='md' $flexDirection='column'>
          {!isNil(drawingOptions['FULLSCREEN']) ? (
            <Box>
              <MapControlsContainer>
                <MapControl.fullscreen
                  $active={!drawingOptions['FULLSCREEN'].active}
                  onPress={executeDrawingOptionPress('FULLSCREEN')}
                  style={mapControlStyle}
                />
              </MapControlsContainer>
            </Box>
          ) : null}
          <MapControlsContainer>
            <MapControl.grab
              $active={drawingOptions['GRAB']?.active}
              onPress={executeDrawingOptionPress('GRAB')}
              style={mapControlStyle}
            />
            <TextTooltip
              disabled={!isDrawPolygonDisabled}
              content={t('models.plot.actions.throw_shape_to_redraw')}
            >
              <MapControl.drawPolygon
                $active={drawingOptions['POLYGON']?.active}
                onPress={executeDrawingOptionPress('POLYGON')}
                style={mapControlStyle}
              />
            </TextTooltip>
            <MapControl.trash
              $active={!drawingOptions['TRASH']?.disabled}
              onPress={executeDrawingOptionPress('TRASH')}
              style={mapControlStyle}
            />
          </MapControlsContainer>
        </Flex>
      ) : null}
    </Container>
  )
}

export default MapBottomControls
