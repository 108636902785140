import { extractPointsBetweenNulls, useChartCtx } from '@weenat/client/dist/core/charts'
import { metricsWithGradient } from '@weenat/client/dist/core/charts/charts'
import { DataPoint } from '@weenat/client/dist/core/charts/d3-charts.type'
import { getColorScalePerMetric, getYDomainPerMetric } from '@weenat/client/dist/core/measurements'
import { AllKey } from '@weenat/client/dist/enums/MetricIds'
import TimeSteps from '@weenat/client/dist/enums/TimeSteps'
import { Unit } from '@weenat/client/dist/enums/UnitChoices'
import { fromColorPathToColor } from '@weenat/theme'
import * as d3 from 'd3'
import { isNil } from 'lodash-es'
import { ReactNode, useId, useMemo } from 'react'

const stops = [0, 0.25, 0.5, 0.75, 1]
const emptyDomain: [number, number] = [0, 100]
const emptyDataPoints: DataPoint[] = []

const defaultColorScale = () => () => 'black'
const defaultScale = d3.scaleLinear()
const defaultDenormalize = (v: number) => v

export type LineProps = {
  data?: DataPoint[]
  color?: string
  children?: ReactNode
  showScatter?: boolean
  metric?: AllKey
  getGradient?: () => { gradientStart: number; gradientEnd: number }
  width?: number
  strokeDashoffset?: string | number | undefined
  strokeDasharray?: string | number | undefined
  scale?: d3.ScaleLinear<number, number>
  denormalize?: (v: number | null) => number | null
  timeStep: TimeSteps
  /** since this component could have data already converted or not we allow passing of unit default assume data are not converted */
  dataUnit?: Unit
  curveLine?: boolean
} & (
  | {
      denormalize: (v: number | null) => number | null
      scale?: never
    }
  | {
      scale: d3.ScaleLinear<number, number>
      denormalize?: never
    }
)

function Line({
  data,
  color,
  showScatter = false,
  metric,
  width = 3,
  scale = defaultScale,
  denormalize = defaultDenormalize,
  timeStep,
  strokeDashoffset,
  strokeDasharray,
  dataUnit,
  curveLine = false
}: LineProps) {
  const { xScale, yScale } = useChartCtx()
  const id = useId()

  const yDomain = !isNil(data)
    ? d3.extent(
        data
          ?.filter((d): d is { value: number; date: Date } => d.value !== null)
          .map((d) => d.value)
      )
    : emptyDomain

  const line = d3
    .line<DataPoint>(
      (d) => xScale(d.date),
      (d) => yScale(scale(d.value))
    )
    .defined((d) => d.value !== null && d.date.getTime() > xScale.domain()[0].getTime())

  if (curveLine) {
    line.curve(d3.curveNatural)
  }

  const linearGradientId = `gradient_${id}`

  const useGradient = !isNil(metric) && metricsWithGradient.includes(metric) && isNil(color)

  const finalColor = useGradient
    ? `url(#${linearGradientId})`
    : color !== undefined
      ? color
      : !isNil(metric)
        ? fromColorPathToColor(`metrics.${metric}.500`)
        : 'black'

  const colors = useMemo(() => {
    const colorScale = !isNil(metric)
      ? getColorScalePerMetric(metric)(getYDomainPerMetric({ metric, timeStep, unit: dataUnit }))
      : defaultColorScale()
    return stops.map((quantile) => {
      return colorScale(denormalize(d3.quantileSorted(scale.domain(), quantile)!)!)
    })
  }, [metric, timeStep, denormalize, scale, dataUnit])

  if (yDomain[0] === undefined) {
    return null
  }

  if (isNil(data)) {
    return null
  }

  // Points that cannot be traced using a line since they have no defined point before or after them
  const standalonePoints = showScatter ? emptyDataPoints : extractPointsBetweenNulls(data)

  const d = !isNil(data) ? line(data) : undefined

  return (
    <>
      {useGradient && (
        <defs>
          <linearGradient
            id={linearGradientId}
            x1='0%'
            y1={`100%`}
            x2='0%'
            y2={`0%`}
            gradientUnits='userSpaceOnUse'
          >
            {colors.map((stopColor, idx) => (
              <stop
                key={`${linearGradientId}-${stopColor}`}
                offset={`${stops[idx] * 100}%`}
                stopColor={stopColor}
              />
            ))}
          </linearGradient>
        </defs>
      )}

      {!isNil(data) && !isNil(d) ? (
        <>
          <path
            fill='none'
            stroke={finalColor}
            strokeWidth={width}
            d={d}
            strokeDasharray={strokeDasharray}
            strokeDashoffset={strokeDashoffset}
          />
          {showScatter ? (
            <g fill='white' stroke={finalColor} strokeWidth={width}>
              {data.map((scatterPoint) =>
                scatterPoint.value !== null ? (
                  <circle
                    key={scatterPoint.date.getTime()}
                    cx={xScale(scatterPoint.date)}
                    cy={yScale(scale(scatterPoint.value))}
                    r='2'
                    fill={finalColor}
                  />
                ) : null
              )}
            </g>
          ) : (
            standalonePoints.map((pt) => (
              <circle
                key={pt.date.getTime()}
                cx={xScale(pt.date)}
                cy={yScale(scale(pt.value))}
                r={width}
                fill={finalColor}
              />
            ))
          )}
        </>
      ) : null}
    </>
  )
}

export default Line
