import { useUserLanguage } from 'app/state'
import GoogleMapReact from 'google-map-react'
import isNil from 'lodash-es/isNil'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { styled } from 'styled-components'
import MapControls from './MapControls'
import UserGeolocationMarker from './UserGeolocationMarker'
import {
  GoogleMapApi,
  LatLng,
  panToLatLngWithOffset as panToLatLngWithOffsetUtility
} from './utils'
import {
  defaultCenter as APP_DEFAULT_CENTER,
  defaultZoom as APP_DEFAULT_ZOOM,
  MapsControlsOptions,
  MapsControlsType,
  defaultControlsTypes,
  mapOptions
} from './utils/defaults'

const MapContainer = styled.div<{ $containerHeight?: number }>`
  width: 100%;
  height: ${(props) => (!isNil(props.$containerHeight) ? `${props.$containerHeight}px` : '100%')};
  margin: 0;
  padding: 0;
  position: relative;
`

type GoogleMapReactProps = React.ComponentProps<typeof GoogleMapReact>

interface StandaloneGoogleMapsProps extends GoogleMapReactProps {
  containerHeight?: number
  controls?: MapsControlsType[]
  controlsOptions?: MapsControlsOptions
  onGoogleApiLoaded?: ({ map, maps }: GoogleMapApi) => void
  defaultZoom?: number
  defaultCenter?: LatLng
  hideControls?: boolean
}

/**
 * Standalone as it doesn't need any background map context to be functional
 */
const StandaloneGoogleMap: FC<StandaloneGoogleMapsProps> = ({
  containerHeight,
  controls = [],
  controlsOptions = {},
  onGoogleApiLoaded,
  children,
  defaultCenter,
  defaultZoom,
  hideControls = false,
  ...rest
}) => {
  const [language] = useUserLanguage()

  const [localGoogleMapApi, setLocalGoogleMapApi] = useState<GoogleMapApi>({
    map: null,
    maps: null
  })
  const [localUserGeolocation, setLocalUserGeolocation] = useState<LatLng | null>(null)

  const { map } = localGoogleMapApi

  const setLocalApi = useCallback(
    (api: GoogleMapApi) => {
      setLocalGoogleMapApi(api)
      onGoogleApiLoaded?.(api)
    },
    [onGoogleApiLoaded, setLocalGoogleMapApi]
  )

  const zoomIn = useCallback(() => {
    if (!isNil(map)) map.setZoom((map.getZoom() as number) + 1)
  }, [map])

  const zoomOut = useCallback(() => {
    if (!isNil(map)) map.setZoom((map.getZoom() as number) - 1)
  }, [map])

  const panToLatLngWithOffset = useCallback(
    (latLng: LatLng) =>
      panToLatLngWithOffsetUtility(localGoogleMapApi, latLng, {
        top: 0,
        left: 0
      }),
    [localGoogleMapApi]
  )

  const panToUserGeolocation = useCallback<PositionCallback>(
    ({ coords: { latitude, longitude } }) => {
      const location: LatLng = { lat: latitude, lng: longitude }
      setLocalUserGeolocation(location)
      if (!isNil(map)) panToLatLngWithOffset(location)
    },
    [map, panToLatLngWithOffset]
  )

  const centerPosition = useMemo(() => {
    if (isNil(defaultCenter)) return
    return { lat: defaultCenter.lat, lng: defaultCenter.lng } as LatLng
  }, [defaultCenter?.lat, defaultCenter?.lng])

  useEffect(() => {
    if (!isNil(map) && !isNil(centerPosition)) panToLatLngWithOffset(centerPosition)
  }, [centerPosition])

  const bootstrapURLKeys = {
    key: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries: ['drawing', 'places', 'geometry'],
    language
  }

  return (
    <MapContainer $containerHeight={containerHeight}>
      <GoogleMapReact
        // Never use the heatmapLibrary prop, otherwise it will override the libraries bootstrap url key
        // see https://github.com/google-map-react/google-map-react/blob/b3c27dfb0e323fb2d554112158cc7bbf2fcae347/src/loaders/google_map_loader.js#L74
        bootstrapURLKeys={bootstrapURLKeys}
        yesIWantToUseGoogleMapApiInternals
        options={mapOptions}
        defaultCenter={defaultCenter ?? APP_DEFAULT_CENTER}
        defaultZoom={defaultZoom ?? APP_DEFAULT_ZOOM}
        onGoogleApiLoaded={setLocalApi}
        {...rest}
      >
        {children}
        {!isNil(localUserGeolocation) ? <UserGeolocationMarker {...localUserGeolocation} /> : null}
      </GoogleMapReact>
      {hideControls ? null : (
        <MapControls
          controls={[...defaultControlsTypes, ...controls]}
          controlsOptions={{
            ['ZOOM']: {
              ['ZOOM_IN']: {
                disabled: false,
                onPress: zoomIn
              },
              ['ZOOM_OUT']: {
                disabled: false,
                onPress: zoomOut
              }
            },
            ['GEOLOCALISATOR']: {
              onSuccess: panToUserGeolocation
            },
            ...controlsOptions
          }}
        />
      )}
    </MapContainer>
  )
}

export default memo(StandaloneGoogleMap)
