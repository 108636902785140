import { getMarkerBackgroundColor, getMarkerColorScale } from '@weenat/client/dist/core/map'
import {
  PointProperties,
  extractValueFromSummaryData,
  getZIndex
} from '@weenat/client/dist/core/measurements'
import { hasHydricStressMetricData } from '@weenat/client/dist/core/summary'
import {
  Universe,
  getAvailableUniversesFromMetrics,
  getMetricsFromSummary
} from '@weenat/client/dist/core/universes'
import { isHydricStress } from '@weenat/client/dist/enums/MetricIds'
import { useConvertedValue } from '@weenat/client/dist/hooks'
import { Device } from '@weenat/client/dist/resources/devices'
import { isPlotSummary, isStationSummary } from '@weenat/client/dist/resources/measurements.type'
import { Station } from '@weenat/client/dist/resources/stations'
import { isArvalisPlotSummary } from '@weenat/client/dist/resources/waterBalanceArvalis'
import { Href } from '@weenat/client/dist/routx/runtime-core'
import { useNavigate } from 'app/routx-router'
import FocusedDeviceSummaryCard from 'app/src/devices/FocusedDeviceSummaryCard'
import { useIsAppAccessRestricted } from 'app/src/hooks/useIsAppAccessRestricted'
import Icons from 'app/src/kit/Icons'
import HydricStressTrendIcon from 'app/src/kit/icons/HydricStressTrendIcon'
import WindDirectionIcon from 'app/src/kit/icons/WindDirectionIcon'
import SvgDiscreteDeviceMarker from 'app/src/kit/icons/generic-icons/DiscreteDeviceMarker'
import SvgDiscretePlotMarker from 'app/src/kit/icons/generic-icons/DiscretePlotMarker'
import { Tooltip, TooltipContent, TooltipTrigger } from 'app/src/kit/tooltips/Tooltip'
import MapMarkerOutline, { DEFAULT_MARKER_SIZE_IN_PX } from 'app/src/map/MapMarkerOutline'
import { UniverseCardSelectorTooltip } from 'app/src/myFarm/tools/UniverseSelector'
import { useToggleFeature, useUniverse, useUniverseHorizon } from 'app/state'
import { DebouncedFunc } from 'lodash-es'
import isNil from 'lodash-es/isNil'
import { readableColor } from 'polished'
import { ReactElement, useCallback, useMemo } from 'react'
import { useTheme } from 'styled-components'
import PlotCard from '../PlotCard'
import StationCard from '../StationCard'
import {
  useBackgroundMapContext,
  useBackgroundMapDispatcher
} from '../contexts/BackgroundMapContext'
import { TOOLTIP_CLOSE_DELAY_MS } from '../hooks/usePODClusters'
import useUniverseBackgroundMapContext from '../universes/useUniverseBackgroundMapContext'
import { isFreemiumAccessibleAggregate } from '../utils/fitBoundsWithPadding'
import MarkerText from './MarkerText'
import RestrictedMarker from './RestrictedMarker'

const HYDRIC_STRESS_MARKER_SIZE = 64
const TOOLTIP_REST_DELAY = 100
const TOOLTIP_STYLE = {
  padding: 0,
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none'
}

const DISCRETE_MARKER_OFFSET = {
  position: 'relative',
  marginTop: '-36px',
  marginLeft: '-36px'
}

const DISCRETE_MARKER_TOOLTIP_OFFSET = {
  marginTop: '-27px', //Create a gap of 9px (I chose it bc we're using multiples of 9 here but this could be changed) over the marker
  marginLeft: '-18px' //Half -36px so it is centered over the marker
}

interface DumbMarkerProps {
  backgroundColor: string
  value: string
  lat: number
  lng: number
  size: number
  shape: 'circle' | 'square'
  zIndex: number
  onPress: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
  isFocused: boolean
}

const DumbMarker: FC<DumbMarkerProps> = ({
  backgroundColor,
  lat,
  lng,
  size,
  shape,
  zIndex,
  onPress,
  onMouseEnter,
  onMouseLeave,
  isFocused,
  children
}) => {
  const radius = shape === 'circle' ? undefined : 8

  return (
    <MapMarkerOutline
      $zIndex={zIndex}
      $isFocused={isFocused}
      $backgroundColor={backgroundColor}
      $size={size}
      $borderRadius={radius}
      $lat={lat}
      $lng={lng}
      onClick={onPress}
      onMouseEnter={(e) => {
        e.stopPropagation()
        onMouseEnter()
      }}
      onMouseLeave={(e) => {
        e.stopPropagation()
        onMouseLeave()
      }}
    >
      {children}
    </MapMarkerOutline>
  )
}

interface PODMarkerProps
  extends Pick<
    PointProperties,
    'isFavorite' | 'countByMetric' | 'summary' | 'isVirtualDeviceByMetric' | 'kind'
  > {
  lat: number
  lng: number
  shape: 'circle' | 'square'
  resourceId: number | string | undefined
  summaryLink: Href
  device?: Device | Station
  isPlotsMap: boolean
  debouncedReset: DebouncedFunc<() => void>
}

/**
 * POD stands for Plot Or Device
 */
const PODMarker: FC<PODMarkerProps> = ({
  resourceId,
  isFavorite,
  countByMetric,
  summaryLink,
  isVirtualDeviceByMetric,
  kind,
  lat,
  lng,
  shape,
  summary,
  device,
  isPlotsMap,
  debouncedReset
}) => {
  const dispatch = useBackgroundMapDispatcher()
  const nav = useNavigate()
  const { colors } = useTheme()
  const { convertValue } = useConvertedValue()
  const [horizon] = useUniverseHorizon()
  const [{ redesign, wr_forecasts }] = useToggleFeature()
  const { focusedMetricId, aggregate, universeAggregate, span } = useUniverseBackgroundMapContext()

  const [currentUniv] = useUniverse()

  const { hoverIntent } = useBackgroundMapContext()
  const isFocused = hoverIntent.itemId === resourceId

  const { isRestricted: isAccessRestrictedByPlan } = useIsAppAccessRestricted({
    enabled: true
  })

  const dataFromSummary =
    !isNil(focusedMetricId) && !isNil(universeAggregate)
      ? extractValueFromSummaryData({
          data: summary.data,
          metricId: focusedMetricId,
          aggregate,
          universeAggregate,
          horizon
        })
      : undefined

  const zIndex = useMemo(
    () =>
      getZIndex({
        isFavorite,
        isFocused,
        metricId: focusedMetricId,
        rawValue: dataFromSummary?.value
      }),
    [isFavorite, isFocused, focusedMetricId, dataFromSummary?.value]
  )

  const availableUniverses = useMemo(() => {
    const measures = getMetricsFromSummary(summary)
    return getAvailableUniversesFromMetrics(measures)
  }, [summary])

  const setHoverIntent = useCallback(() => {
    debouncedReset.cancel()
    dispatch({
      type: 'setHoverIntent',
      newHoverIntent: {
        itemId: resourceId,
        source: 'marker'
      }
    })
  }, [dispatch, resourceId, debouncedReset])

  const cancelDebouncedReset = useCallback(() => {
    debouncedReset.cancel()
  }, [debouncedReset])

  const invokeDebouncedResetImmediately = useCallback(() => {
    debouncedReset()
    debouncedReset.flush()
  }, [debouncedReset])

  const pressAndClearIntent = useCallback(() => {
    invokeDebouncedResetImmediately()
    nav(summaryLink)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryLink, invokeDebouncedResetImmediately])

  // Discrete markers when in Overview universe
  if (currentUniv === Universe.overview) {
    if (!redesign) {
      return null
    }
    return (
      <Tooltip enableSafePolygon restMs={TOOLTIP_REST_DELAY} closeDelay={TOOLTIP_CLOSE_DELAY_MS}>
        <TooltipTrigger onMouseEnter={setHoverIntent} onMouseLeave={debouncedReset}>
          {kind === 'plot' ? (
            <SvgDiscretePlotMarker
              lat={lat}
              lng={lng}
              style={{ ...DISCRETE_MARKER_OFFSET, zIndex: zIndex }}
              color={isFocused ? colors.primary['500'] : undefined}
            />
          ) : (
            <SvgDiscreteDeviceMarker
              lat={lat}
              lng={lng}
              style={{ ...DISCRETE_MARKER_OFFSET, zIndex: zIndex }}
              onClick={pressAndClearIntent}
              color={isFocused ? colors.primary['500'] : undefined}
            />
          )}
        </TooltipTrigger>
        <TooltipContent
          style={{ ...TOOLTIP_STYLE, ...DISCRETE_MARKER_TOOLTIP_OFFSET }}
          onMouseEnter={cancelDebouncedReset}
          onMouseLeave={debouncedReset}
        >
          {!isNil(device) && isFocused && availableUniverses.length > 1 && (
            <UniverseCardSelectorTooltip
              summary={summary}
              summaryLink={summaryLink}
              handleClick={pressAndClearIntent}
              handleClose={invokeDebouncedResetImmediately}
            />
          )}
          {isPlotsMap && isPlotSummary(summary) && isFocused && availableUniverses.length === 1 && (
            <PlotCard
              summary={summary}
              focusedHorizon={null}
              focusedMetric={null}
              onPress={pressAndClearIntent}
              onClose={invokeDebouncedResetImmediately}
              hasNoDataForHorizon
            />
          )}
          {isPlotsMap &&
            isStationSummary(summary) &&
            isFocused &&
            availableUniverses.length === 1 && (
              <StationCard
                summary={summary}
                focusedHorizon={null}
                focusedMetric={null}
                onPress={pressAndClearIntent}
                onClose={invokeDebouncedResetImmediately}
                hasNoDataForHorizon
              />
            )}
        </TooltipContent>
      </Tooltip>
    )
  }

  if (isNil(focusedMetricId)) {
    return null
  }

  // Discrete markers when in any other universe
  if (!countByMetric.has(focusedMetricId)) {
    if (!redesign) {
      return null
    }
    return (
      <Tooltip enableSafePolygon restMs={TOOLTIP_REST_DELAY} closeDelay={TOOLTIP_CLOSE_DELAY_MS}>
        <TooltipTrigger onMouseEnter={setHoverIntent} onMouseLeave={debouncedReset}>
          {kind === 'plot' ? (
            <SvgDiscretePlotMarker
              lat={lat}
              lng={lng}
              style={{ ...DISCRETE_MARKER_OFFSET, zIndex: zIndex }}
              onClick={pressAndClearIntent}
              color={isFocused ? colors.primary['500'] : undefined}
            />
          ) : (
            <SvgDiscreteDeviceMarker
              lat={lat}
              lng={lng}
              style={{ ...DISCRETE_MARKER_OFFSET, zIndex: zIndex }}
              onClick={pressAndClearIntent}
              color={isFocused ? colors.primary['500'] : undefined}
            />
          )}
        </TooltipTrigger>
        <TooltipContent
          style={{ ...TOOLTIP_STYLE, ...DISCRETE_MARKER_TOOLTIP_OFFSET }}
          onMouseEnter={cancelDebouncedReset}
          onMouseLeave={debouncedReset}
        >
          {!isPlotsMap && !isNil(device) && isFocused ? (
            <FocusedDeviceSummaryCard
              device={device}
              summary={summary.data}
              onClose={invokeDebouncedResetImmediately}
            />
          ) : null}
          {isPlotsMap && isPlotSummary(summary) && isFocused && (
            <PlotCard
              summary={summary}
              focusedHorizon={null}
              focusedMetric={null}
              onPress={pressAndClearIntent}
              onClose={invokeDebouncedResetImmediately}
              hasNoDataForHorizon
            />
          )}
          {isPlotsMap && isStationSummary(summary) && isFocused && (
            <StationCard
              summary={summary}
              focusedHorizon={null}
              focusedMetric={null}
              onPress={pressAndClearIntent}
              onClose={invokeDebouncedResetImmediately}
              hasNoDataForHorizon
            />
          )}
        </TooltipContent>
      </Tooltip>
    )
  }

  const isRestricted = isAccessRestrictedByPlan
    ? isVirtualDeviceByMetric.get(focusedMetricId)
      ? !isFreemiumAccessibleAggregate(universeAggregate)
      : true
    : false

  if (isRestricted) {
    return <RestrictedMarker key={resourceId} lat={lat} lng={lng} />
  }

  const { value, threshold, trend } = dataFromSummary ?? {
    value: null,
    threshold: null,
    trend: null
  }

  const colorScale = getMarkerColorScale(focusedMetricId, span)
  const backgroundColor = getMarkerBackgroundColor({
    rawValue: value,
    colorScale,
    colors,
    focusedMetricId,
    threshold,
    isRestricted
  })

  const displayed = convertValue({ metric: focusedMetricId, value, displayUnit: false })

  let valueElement: ReactElement | undefined

  const textColor = readableColor(backgroundColor)

  const isArvalisPlot = isArvalisPlotSummary(summary)
  const plotIsNotSetupForCurrentSeason = isArvalisPlot && !summary.plotIsSetupForCurrentSeason
  const shouldConfigureMetric =
    isPlotSummary(summary) && !isNil(focusedMetricId)
      ? summary.metadata.notConfigured?.includes(focusedMetricId) ?? false
      : false

  if (!isNil(value) && Number.isFinite(value)) {
    if (focusedMetricId === 'DD') {
      valueElement = (
        <WindDirectionIcon
          windOrigin={value}
          $color='grayscale.white'
          $size={wr_forecasts ? 'lg' : 'sm'}
        />
      )
    } else if (!isNil(focusedMetricId) && isHydricStress(focusedMetricId) && !isNil(trend)) {
      valueElement = (
        <Flex $flexDirection='column' $alignItems='center'>
          <HydricStressTrendIcon trend={trend} $color={textColor} $size='md' />
          <MarkerText $color={textColor}>{displayed}</MarkerText>
        </Flex>
      )
    }
  } else if (plotIsNotSetupForCurrentSeason || shouldConfigureMetric) {
    valueElement = <Icons.Wrench $size='lg' />
  } else {
    valueElement = <Icons.MinusSign $size='lg' />
  }

  return (
    <Tooltip enableSafePolygon restMs={TOOLTIP_REST_DELAY} closeDelay={TOOLTIP_CLOSE_DELAY_MS}>
      <TooltipTrigger>
        <DumbMarker
          lat={lat}
          lng={lng}
          backgroundColor={backgroundColor}
          value={displayed}
          onPress={pressAndClearIntent}
          zIndex={zIndex}
          shape={shape}
          size={
            !isNil(focusedMetricId) && isHydricStress(focusedMetricId)
              ? HYDRIC_STRESS_MARKER_SIZE
              : DEFAULT_MARKER_SIZE_IN_PX
          }
          onMouseEnter={setHoverIntent}
          onMouseLeave={debouncedReset}
          isFocused={isFocused}
        >
          {isNil(valueElement) ? (
            <MarkerText $color={readableColor(backgroundColor)}>{displayed}</MarkerText>
          ) : (
            valueElement
          )}
        </DumbMarker>
      </TooltipTrigger>
      <TooltipContent
        style={TOOLTIP_STYLE}
        onMouseEnter={cancelDebouncedReset}
        onMouseLeave={debouncedReset}
      >
        {!isPlotsMap && !isNil(device) && isFocused ? (
          <FocusedDeviceSummaryCard
            device={device}
            summary={summary.data}
            onClose={invokeDebouncedResetImmediately}
          />
        ) : null}
        {isPlotsMap && isPlotSummary(summary) && isFocused ? (
          <Flex $width={'384px'}>
            <PlotCard
              summary={summary}
              focusedHorizon={horizon}
              focusedMetric={focusedMetricId}
              onPress={pressAndClearIntent}
              onClose={invokeDebouncedResetImmediately}
              hasNoDataForHorizon={
                !isNil(focusedMetricId) &&
                isHydricStress(focusedMetricId) &&
                !hasHydricStressMetricData({
                  metric: focusedMetricId,
                  horizonId: horizon,
                  summary
                })
              }
            />
          </Flex>
        ) : null}
        {isPlotsMap && isStationSummary(summary) && isFocused ? (
          <Flex $width={'384px'}>
            <StationCard
              summary={summary}
              focusedHorizon={horizon}
              focusedMetric={focusedMetricId}
              onPress={pressAndClearIntent}
              onClose={invokeDebouncedResetImmediately}
            />
          </Flex>
        ) : null}
      </TooltipContent>
    </Tooltip>
  )
}

export default PODMarker
