import { schemas, useClient } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import { clearAllStates } from 'app/src/authentication/components/Logout'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import EmailField from 'app/src/kit/fields/EmailField'
import { useLoginAs, useToken } from 'app/state'
import isNil from 'lodash-es/isNil'
import { useLocation } from 'react-router-dom'

const schema = schemas.shared.mailed

function formatString(str: string) {
  return str.trim().toLowerCase()
}

type InitEmailProps = { initialEmail?: string; unknownEmail?: string }

const LoginAs = ({ initialEmail, unknownEmail }: InitEmailProps) => {
  const nav = useNavigate()
  const location = useLocation()
  const { t } = useIntl()
  const client = useClient()
  const [loginAs, setLoginAs] = useLoginAs()
  const [token] = useToken()

  const handleSubmit = async ({ email }: { email: string }) => {
    if (!isNil(token)) {
      const formattedEmail = formatString(email)

      try {
        /** we don't know if email will be valid so we are not using react-query here
         * otherwize it's too difficult to keep meRequest and debug meRequest in sync
         * here once query is valid we setLoginAs and flush all query from react-query
         * which will make userProvider to refetch data with loginAs and make debug successful
         **/
        const me = await client.me.get({ loginAsEmail: formattedEmail }).request()
        await clearAllStates(true)
        setLoginAs({ email: me.email, id: me.id })
        nav(`/farms`)
      } catch (err) {
        nav(`/me/debug?unknownEmail=${formattedEmail}`)
      }
    }
  }

  if (!isNil(initialEmail) && loginAs?.email !== initialEmail) {
    handleSubmit({ email: initialEmail })
  }

  return (
    <SuperForm
      schema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        email: !isNil(location.state?.email) ? location.state?.email : initialEmail
      }}
    >
      <Flex $flexDirection='column' $flex={1}>
        <EmailField $displayedError={`email incorrect ${unknownEmail}`} />
        <Flex $alignSelf='flex-end'>
          <SubmitButton isError={!isNil(unknownEmail)}>{t('actions.confirm')}</SubmitButton>
        </Flex>
      </Flex>
    </SuperForm>
  )
}

export default LoginAs
