import { QueryKey } from '@tanstack/react-query'
import { enums } from '@weenat/client'
import { getDeviceCommonName } from '@weenat/client/dist/core/devices'
import { HorizonOpt } from '@weenat/client/dist/core/horizons'
import { getCountByMetric, useMetricProvider } from '@weenat/client/dist/core/metrics'
import { useMyFarmItemActions } from '@weenat/client/dist/core/my-farm'
import { useToggleIsFavorite } from '@weenat/client/dist/core/plots'
import { getMetricLastUpdateFromSummary } from '@weenat/client/dist/core/summary'
import {
  BackgroundMapUniverseMetric,
  Universe,
  UniverseMetric,
  allUniverseMetrics,
  getAvailableUniversesFromMetrics
} from '@weenat/client/dist/core/universes'
import { CommonKey, MeasurementKey } from '@weenat/client/dist/enums/MetricIds'
import { DeviceAvailableMeasure } from '@weenat/client/dist/resources'
import {
  PlotSummary,
  StationSummary,
  isPlotSummary,
  isStationSummary
} from '@weenat/client/dist/resources/measurements.type'
import { Plot } from '@weenat/client/dist/resources/plots'
import { Station } from '@weenat/client/dist/resources/stations'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import useDisclosure from 'app/src/hooks/useDisclosure'
import { RestrictionReason, useIsAppAccessRestricted } from 'app/src/hooks/useIsAppAccessRestricted'
import useMapIntent from 'app/src/hooks/useMapIntent'
import useToasts from 'app/src/hooks/useToasts'
import ActionItem from 'app/src/kit/ActionItem'
import Card from 'app/src/kit/Card'
import DelimitedBox from 'app/src/kit/DelimitedBox'
import { IconProps } from 'app/src/kit/Icon'
import Icons from 'app/src/kit/Icons'
import StackedMetrics from 'app/src/kit/StackedMetrics'
import StackedUniverses from 'app/src/kit/StackedUniverses'
import Text from 'app/src/kit/Text'
import UpdateInformation from 'app/src/kit/UpdateInformation'
import {
  Dropdown,
  DropdownContent,
  DropdownTrigger
} from 'app/src/kit/fields/Select/SelectDropdown'
import TextTooltip from 'app/src/kit/tooltips/TextTooltip'
import { useUniverse } from 'app/state'
import { isEmpty } from 'lodash-es'
import isNil from 'lodash-es/isNil'
import { MouseEvent, ReactNode, memo, useCallback, useMemo } from 'react'
import { styled } from 'styled-components'
import PlotListItemMetricSummary from './plotListItem/PlotListItemMetricSummary'

export const PLOT_OR_STATION_CARD_BORDER_WIDTH = 1
export const PLOT_OR_STATION_CARD_HEIGHT = 64

const Titles = styled(Flex)`
  flex: 1;
  align-items: center;
  gap: 4px;
  overflow: hidden;
`

const ActionsContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding: ${(p) => p.theme.spacings.md}px;
  gap: ${(p) => p.theme.spacings.md}px;
`

const StyledCard = styled(Card)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: ${PLOT_OR_STATION_CARD_HEIGHT}px;
  border-radius: 0px;
  border-width: 0 0 ${PLOT_OR_STATION_CARD_BORDER_WIDTH}px 0;
  border-color: ${(p) => p.theme.colors.grayscale[100]};

  &:hover {
    border-color: ${(p) => p.theme.colors.primary[500]};
    cursor: pointer;
  }

  padding: 0;
  transition: border-color 0.2s ease-in-out;
  overflow: hidden;
`

interface PlotOrStationCardInfoProps {
  name: string
  reverseGeocoding: string | null
  Icon: ReactNode
  kind: 'plot' | 'station'
  deviceCount: number
  hasVirtualDevice: boolean
}

interface PlotOrStationCardMetricsProps {
  metric: BackgroundMapUniverseMetric | undefined
  summary: PlotSummary | StationSummary
  isVirtualDevice: boolean
  isDiscretePOS: boolean
  availableMetrics: DeviceAvailableMeasure[]
  needsConfiguration?: (MeasurementKey | CommonKey)[] | null | boolean
  warningThreshold?: number
  timestamp: number | undefined
  providerName: string | undefined
  data: PlotSummary['data']
  isRestricted: boolean
  reason: RestrictionReason
  provider?: string
  focusedHorizon: number | null
}

interface PlotOrStationCardActionsProps {
  item: Pick<Plot | Station, 'id' | 'location'>
  kind: 'plot' | 'station'
  isFavorite: boolean
  isFavoriteRequestLoading: boolean
  onFavoritePress: IconProps['onPress']
}

interface PlotOrStationCardProps {
  availableHorizonOptions: HorizonOpt[]
  focusedHorizon: number | null
  focusedMetric: UniverseMetric
  hasNoDataForHorizon: boolean
  onHorizonChange: (newHorizon: number | null) => void
  summariesQueryCacheKey: QueryKey
  summary: PlotSummary | StationSummary
  isExpertOrg: boolean
  onPress?: () => void
  isTooltip?: boolean
  onClose?: () => void
}

const PlotOrStationCardInfo: FC<PlotOrStationCardInfoProps> = ({
  name,
  reverseGeocoding,
  kind,
  Icon,
  deviceCount,
  hasVirtualDevice
}) => {
  const { t } = useIntl()
  const org = useOrgContext()

  return (
    <Flex $alignItems='center' $p='lg' $gap='lg'>
      {Icon}
      <Titles>
        <Flex $flexDirection='column' $alignItems='start' $gap='sm'>
          <Text $fontWeight='bold' $ellipsis style={{ maxWidth: '28vw' }}>
            {name}
          </Text>
          <Flex $flexDirection='row' $gap='sm'>
            <Text $fontSize='sm' $color={'grayscale.700'} $ellipsis style={{ maxWidth: '17vw' }}>
              {!isNil(reverseGeocoding) && !isEmpty(reverseGeocoding) ? reverseGeocoding : '-'}
            </Text>
            {kind === 'plot' ? (
              <>
                {deviceCount !== 0 ? (
                  <>
                    {'·'}
                    <Text $fontSize='sm' $fontWeight='semiBold' $color={'secondary.500'} $ellipsis>
                      {deviceCount == 1
                        ? t('models.device.model.singular_item_count', { count: deviceCount })
                        : t('models.device.model.item_count', { count: deviceCount })}
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                {hasVirtualDevice ? (
                  <>
                    {'·'}
                    <Text $fontSize='sm' $fontWeight='semiBold' $color={'primary.500'} $ellipsis>
                      {t(
                        org.billingStatus === enums.BillingStatus.expert
                          ? 'dataSources.meteo_vision'
                          : 'dataSources.spatialized_weather'
                      )}
                    </Text>
                  </>
                ) : null}
              </>
            ) : null}
          </Flex>
        </Flex>
      </Titles>
    </Flex>
  )
}

const PlotOrStationCardActions: FC<PlotOrStationCardActionsProps> = ({
  item,
  kind,
  isFavoriteRequestLoading,
  isFavorite,
  onFavoritePress
}) => {
  const { t } = useIntl()
  const org = useOrgContext()

  const { isOpen, toggle, close } = useDisclosure()
  const mapAppUrl = useMapIntent({
    lat: item.location?.coordinates[1],
    lng: item.location?.coordinates[0]
  })

  const groups = useMyFarmItemActions({
    itemId: item.id,
    itemKind: kind,
    currentOrgId: org.id,
    onGeolocationActionPress: () => {
      window.open(mapAppUrl, '_blank')
    },
    newTabHandler: () => {
      window.open(
        `${
          // eslint-disable-next-line no-restricted-properties
          window.location.href.split('?')[0] +
          '/' +
          (kind === 'plot' ? 'plots' : 'stations') +
          '/' +
          item.id
        }`,
        '_blank'
      )
    }
  })

  const handlePress = (e: MouseEvent<Element>) => {
    e.preventDefault()
    toggle()
  }

  return (
    <ActionsContainer>
      <TextTooltip
        restMs={1000}
        content={t(`actions.${isFavorite ? 'remove_from_favorite' : 'add_to_favorite'}`)}
      >
        <Icons.Star
          $size='lg'
          $isLoading={isFavoriteRequestLoading}
          onPress={onFavoritePress}
          $color={isFavorite ? 'primary.500' : 'grayscale.300'}
        />
      </TextTooltip>
      <Dropdown isVisible={isOpen} onVisibleChange={toggle} marginFromParent={8}>
        <DropdownTrigger>
          <Icons.MoreHoriz
            onPress={handlePress}
            $size='lg'
            $backgroundColor={isOpen ? 'primary.200' : undefined}
          />
        </DropdownTrigger>
        <DropdownContent width={392}>
          {groups.map(({ id, actions }) =>
            !isEmpty(actions) ? (
              <DelimitedBox key={id} $py={'sm'}>
                {actions.map((action) => {
                  return <ActionItem key={action.id} action={action} onActionFinished={close} />
                })}
              </DelimitedBox>
            ) : null
          )}
        </DropdownContent>
      </Dropdown>
    </ActionsContainer>
  )
}

const PlotOrStationCardMetrics: FC<PlotOrStationCardMetricsProps> = ({
  metric,
  summary,
  isVirtualDevice,
  isDiscretePOS,
  availableMetrics,
  needsConfiguration,
  warningThreshold,
  timestamp,
  providerName,
  data,
  isRestricted,
  reason,
  provider,
  focusedHorizon
}) => {
  const { t } = useIntl()
  const [currentUniv] = useUniverse()

  const { countByMetric } = getCountByMetric(summary)
  const metricsArrayFromCount = useMemo(() => [...countByMetric.keys()], [countByMetric])
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-expect-error
  const availableUniverses = getAvailableUniversesFromMetrics(metricsArrayFromCount)
  const sortedMetrics = useMemo(
    () =>
      metricsArrayFromCount.sort((a, b) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        allUniverseMetrics.indexOf(a) > allUniverseMetrics.indexOf(b) ? 1 : -1
      }),
    [metricsArrayFromCount]
  )

  return (
    <Flex
      $flexDirection='column'
      $alignItems='flex-end'
      $gap='md'
      $py='md'
      $justifyContent='center'
    >
      {currentUniv === Universe.overview && (
        <Flex $flexDirection='row' $gap='lg'>
          {isPlotSummary(summary) && !isEmpty(availableUniverses) && (
            <StackedUniverses universes={availableUniverses} withTooltipLabel />
          )}
          {!isEmpty(availableMetrics) && (
            <StackedMetrics metrics={sortedMetrics} withTooltipLabel />
          )}
        </Flex>
      )}
      {currentUniv !== Universe.overview &&
        !isDiscretePOS &&
        (isNil(needsConfiguration) ||
          isEmpty(needsConfiguration) ||
          needsConfiguration === false) && (
          <>
            <PlotListItemMetricSummary
              metric={metric}
              data={data}
              focusedHorizon={focusedHorizon}
              isVirtualDevice={isVirtualDevice}
              isRestricted={isRestricted}
              reason={reason}
              provider={provider}
              variant={'list'}
            />
            <Flex>
              <UpdateInformation
                timestamp={timestamp}
                isVirtualMetric={isVirtualDevice}
                warningThreshold={warningThreshold}
                name={providerName}
              />
            </Flex>
          </>
        )}
      {currentUniv !== Universe.overview &&
        !isNil(needsConfiguration) &&
        !isNil(metric) &&
        Array.isArray(needsConfiguration) &&
        needsConfiguration.includes(metric) && (
          <Flex
            $backgroundColor='grayscale.100'
            $py='md'
            $px='lg'
            $gap='md'
            $borderRadius='sm'
            $alignItems='center'
          >
            <Icons.Wrench $size='sm' />
            <Text $fontWeight='medium' $fontSize='sm'>
              {t('models.plot.misc.required_informations')}
            </Text>
          </Flex>
        )}
    </Flex>
  )
}

const MemoPlotOrStationCardInfo = memo(PlotOrStationCardInfo)
const MemoPlotOrStationCardActions = memo(PlotOrStationCardActions)
const MemoPlotOrStationCardMetrics = memo(PlotOrStationCardMetrics)

const PlotOrStationCard: FC<PlotOrStationCardProps> = ({
  focusedHorizon = null,
  focusedMetric,
  summariesQueryCacheKey,
  summary,
  isExpertOrg
}) => {
  const { t } = useIntl()
  const { addSuccessToast } = useToasts()

  const item = isPlotSummary(summary) ? summary.metadata.plot : summary.metadata.station

  const { countByMetric } = getCountByMetric(summary)
  const isDiscretePOS = !countByMetric.has(focusedMetric)

  const device = isPlotSummary(summary)
    ? summary.metadata.plot.currentMeasurementConfigs.find(
        (measure) => measure.metric === (focusedMetric ?? 'RR')
      )
    : undefined

  const availableMeasuresArray = isPlotSummary(summary)
    ? [
        ...new Set(
          summary.metadata.plot.currentMeasurementConfigs
            .filter(
              (measure) =>
                !isNil(device) && 'deviceId' in device && measure.deviceId === device?.deviceId
            )
            .map((measure) => measure.metric)
        )
      ]
    : summary.metadata.station.availableMeasures

  const needsConfiguration = isPlotSummary(summary) && summary.metadata.notConfigured
  const hasVirtualDevice = isPlotSummary(summary)
    ? !isNil(summary.metadata.plot.hasVirtualDevice)
      ? summary.metadata.plot.hasVirtualDevice
      : // to remove when back send us hasVirtualDevice on summary
        !isEmpty(
          summary.metadata.plot.currentMeasurementConfigs.filter(
            (c) =>
              c.deviceId === null && c.metric !== 'etp' && c.metric !== 'SSI' && c.metric != 'T_DEW'
          )
        )
    : false

  const {
    isVirtualDevice,
    warningThreshold,
    name: providerName
  } = useMetricProvider({
    resource: item,
    metric: focusedMetric ?? 'RR',
    isExpertOrg
  })

  const deviceCommonName = !isNil(device)
    ? getDeviceCommonName({
        availableMeasures: availableMeasuresArray,
        brand: device.brand!,
        endUserName: [device.model, '-']
      })
    : isStationSummary(summary)
      ? getDeviceCommonName({
          availableMeasures: summary.metadata.station.availableMeasures,
          brand: summary.metadata.station.brand,
          endUserName: summary.metadata.station.endUserName
        })
      : providerName

  const { name, isFavorite } = item

  const { mutation: toggleIsFavorite, request: isFavoriteRequest } = useToggleIsFavorite({
    summary,
    summariesQueryCacheKey,
    onSuccess: (data) => {
      addSuccessToast(
        t(
          data.isFavorite
            ? 'actions.add_plot_to_favorites_success'
            : 'actions.remove_plot_from_favorites_success',
          { plotName: name }
        )
      )
    }
  })

  const onFavoritePress = useCallback(
    (e: MouseEvent<Element>) => {
      // Otherwise we open the summary
      e.preventDefault()
      toggleIsFavorite()
    },
    [toggleIsFavorite]
  )

  const lastUpdate = getMetricLastUpdateFromSummary({
    summary,
    metric: focusedMetric,
    horizonId: focusedHorizon
  })

  const { isRestricted, reason } = useIsAppAccessRestricted()

  const deviceCount = isPlotSummary(summary)
    ? new Set(
        summary.metadata.plot.currentMeasurementConfigs
          .map((measure) => measure.deviceId)
          .filter((measure) => !isNil(measure))
      ).size
    : 1

  return (
    <StyledCard>
      <MemoPlotOrStationCardInfo
        name={name}
        reverseGeocoding={item.reverseGeocoding}
        kind={isPlotSummary(summary) ? 'plot' : 'station'}
        Icon={
          isPlotSummary(summary) ? (
            <Icons.PlotsFilled $size='lg' />
          ) : (
            <Icons.SingleSensorFilled $size='lg' />
          )
        }
        deviceCount={deviceCount}
        hasVirtualDevice={hasVirtualDevice}
      />
      <Flex $gap='md' $px='md'>
        <MemoPlotOrStationCardMetrics
          metric={focusedMetric}
          summary={summary}
          isVirtualDevice={isVirtualDevice}
          isDiscretePOS={isDiscretePOS}
          availableMetrics={availableMeasuresArray}
          needsConfiguration={needsConfiguration}
          warningThreshold={warningThreshold}
          timestamp={lastUpdate}
          providerName={isVirtualDevice ? providerName : deviceCommonName}
          data={summary.data}
          isRestricted={isRestricted}
          reason={reason}
          provider={providerName}
          focusedHorizon={focusedHorizon}
        />

        <MemoPlotOrStationCardActions
          item={item}
          kind={isPlotSummary(summary) ? 'plot' : 'station'}
          isFavorite={isFavorite}
          isFavoriteRequestLoading={isFavoriteRequest.isPending}
          onFavoritePress={onFavoritePress}
        />
      </Flex>
    </StyledCard>
  )
}

export default memo(PlotOrStationCard)
