import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Text from 'app/src/kit/Text'
import { useToggleFeature } from 'app/state'
import Icons from './Icons'
import Modal from './Modal'
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltips/Tooltip'

const WindHeightExplanation = () => {
  const { t } = useIntl()
  const { open, close, isOpen } = useDisclosure()
  const [{ wr_forecasts }] = useToggleFeature()

  return wr_forecasts ? (
    <Tooltip placement='top'>
      <TooltipTrigger>
        <Icons.InfoCircle onPress={open} $size='rg' $color={'grayscale.700'} />
      </TooltipTrigger>
      <TooltipContent>
        <Flex $flexDirection='column' $maxWidth={480} $gap={'lg'}>
          <Text $fontWeight='bold' $color={'grayscale.white'}>
            {t('metrics.FF', { capitalize: true })}
          </Text>
          <Text $color={'grayscale.white'}>{t('help.wind_height_explanation')}</Text>
        </Flex>
      </TooltipContent>
    </Tooltip>
  ) : (
    <>
      <Icons.InfoCircle onPress={open} $size='rg' $color={'metrics.FF.500'} />
      <Modal title={t('help.wind_reading_aid_title')} close={close} isOpen={isOpen} width={480}>
        <Flex $flexDirection='column' $gap={'lg'}>
          <Text>{t('help.wind_reading_aid_content')}</Text>
          <Flex $flexDirection='column' $gap={'md'}>
            <Text $fontWeight='bold'>{t('metrics.FF', { capitalize: true })}</Text>
            <Text>{t('help.wind_height_explanation')}</Text>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}

export default WindHeightExplanation
