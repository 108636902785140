/* eslint-disable global-require */
import {
  ClientProvider,
  GlobalClientConfigProvider,
  queryClient,
  useClient,
  useQuery
} from '@weenat/client'
import { PossibleError } from '@weenat/client/dist/errors'
import { IntlProvider as WintlProvider } from '@weenat/wintl'
import * as i18nCountries from 'i18n-iso-countries'
import isNil from 'lodash-es/isNil'
import { default as momentTZ } from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'

// eslint-disable-next-line no-restricted-imports
import { ToastProvider } from 'react-toast-notifications'

import { str, useToken } from 'app/state'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from './GlobalStyles'
import client from './client'
import { CURRENT_ORG_KEY } from './orgProvider'
import useToasts from './src/hooks/useToasts'
import Toast from './src/kit/Toast'
import theme from './theme'
import { detectBrowserLanguage } from './utils'

async function dynamicImportBasedOnLocale(locale: string) {
  const all = await Promise.all([
    fetch(`https://cdn.jsdelivr.net/npm/i18n-iso-countries@7.6.0/langs/${locale}.json`)
  ])
  i18nCountries.registerLocale(await all[0].json())
}

const ConnectedIntlProvider: FC = ({ children }) => {
  const [token] = useToken()
  const runtimeClient = useClient()
  const myProfileRequest = useQuery(runtimeClient.me.getMyProfile(), {
    enabled: !isNil(token)
  })
  const [localeLoaded, setLocaleLoaded] = useState<Record<string, boolean>>({})

  const { language } = myProfileRequest.data || {}
  const locale = language || detectBrowserLanguage()

  useEffect(() => {
    const launchPolyfill = async () => {
      if (!isNil(locale)) {
        await dynamicImportBasedOnLocale(locale)
        setLocaleLoaded({ ...localeLoaded, [locale]: true })
      }
    }

    momentTZ.locale(locale)
    launchPolyfill()
  }, [locale])

  const { addToast } = useToasts()

  return (
    <WintlProvider
      locale={locale}
      onUpdateLocaleFail={() => {
        // Can not use intl since the provider is not yet rendered
        addToast('Failed to update language', { appearance: 'error' })
      }}
    >
      {!isNil(locale) && localeLoaded[locale] ? children : null}
    </WintlProvider>
  )
}

const ClientConfigProviderLogoutAware: FC = ({ children }) => {
  const config = useMemo(
    () => ({
      throwOnError: (error: PossibleError) => {
        if ('status' in error && typeof error.status === 'number' && error.status === 401) {
          localStorage.removeItem(CURRENT_ORG_KEY)
          str.flush({ except: ['lightTour', 'clusterSettings', 'features'] })
          queryClient.clear()
          //we don't want to use router here  // since it may be not initialize at this point
          // eslint-disable-next-line no-restricted-properties
          window.location?.assign('/auth/login')
        }
        return false
      }
    }),
    []
  )

  return <GlobalClientConfigProvider config={config}>{children}</GlobalClientConfigProvider>
}
const Providers: FC = ({ children }) => {
  return (
    <str.Providers>
      <HelmetProvider>
        <ClientProvider client={client}>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <ToastProvider autoDismiss components={{ Toast }} placement='bottom-right'>
              <ClientConfigProviderLogoutAware>
                <ConnectedIntlProvider>{children}</ConnectedIntlProvider>
              </ClientConfigProviderLogoutAware>
            </ToastProvider>
          </ThemeProvider>

          {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        </ClientProvider>
      </HelmetProvider>
    </str.Providers>
  )
}

export default Providers
