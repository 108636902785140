import { DataPoint } from '@weenat/client/dist/core/charts/d3-charts.type'
import { isNil } from 'lodash-es'
import { memo } from 'react'
import ChartGutter from './ChartGutter'
import WindDirection from './WindDirection'

export const WIND_DIRECTION_GUTTER_HEIGHT = 24

const renderWindDirection = ({
  date,
  value,
  x,
  y
}: {
  date: Date
  value: number
  y: number
  x: number
}) => {
  return !isNil(value) ? (
    <WindDirection key={`wind_direction_${date.getTime()}`} x={x} y={y} value={value} />
  ) : null
}

interface WindDIrectionGutterProps {
  data: DataPoint[]
}

const WindDirectionGutter = ({ data }: WindDIrectionGutterProps) => {
  return (
    <ChartGutter
      height={WIND_DIRECTION_GUTTER_HEIGHT}
      data={data}
      renderItem={renderWindDirection}
    />
  )
}

export default memo(WindDirectionGutter)
