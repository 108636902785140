import { styled } from 'styled-components'
import MapBottomControls, { MapBottomControlsProps } from './MapBottomControls'
import { MapTopControlsArea } from './MapTopControls'

const MapControlsContainer = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  pointer-events: none;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

const MapControls: FC<MapBottomControlsProps> = (props) => (
  <MapControlsContainer>
    <MapTopControlsArea />
    <MapBottomControls {...props} />
  </MapControlsContainer>
)

export default MapControls
