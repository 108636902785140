import {
  CompleteTargets,
  Target,
  convertTargetsToTargetsWithTz,
  getTargetTriggerStatus
} from '@weenat/client/dist/core/alerts'
import useGetCompleteAlertTargets from '@weenat/client/dist/core/alerts/useGetCompleteAlertTargets'
import { isDevice, isPlot } from '@weenat/client/dist/core/utils/resourcesTypeCheckers'
import useItemName from '@weenat/client/dist/core/utils/useItemName'
import {
  Alert,
  Predicate,
  PredicateStatus,
  Source,
  TargetsWithTZ,
  isTargetsWithTZ
} from '@weenat/client/dist/resources/alerts.types'
import { Org } from '@weenat/client/dist/resources/orgs'
import { useIntl } from '@weenat/wintl'
import Button from 'app/src/kit/Button'
import Table, { TableCell, TableColumn } from 'app/src/kit/Table'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { useCallback, useMemo } from 'react'
import Icons from '../kit/Icons'
import TextTooltip from '../kit/tooltips/TextTooltip'
import {
  CumulGtGDDProgress,
  CumulGtLW_DProgress,
  CumulGtRRProgress
} from './AlertProgressIndicators'

const SMALLER_CELL_WIDTH = 96
const WARNING_CELL_WIDTH = 32

interface TargetRowProps {
  isAllowingEdition?: boolean
  isShowingStatuses?: boolean
  isShowingWarnings?: boolean
  status?: Alert['statuses']['plots' | 'devices'][number]
  predicates?: Alert['predicates']
  hasWarning?: boolean
  onRemove: () => void
  target: CompleteTargets
  isCurrentlyTriggered?: boolean
}

const TargetRow: FC<TargetRowProps> = ({
  isCurrentlyTriggered = false,
  isAllowingEdition = true,
  isShowingStatuses = true,
  isShowingWarnings = false,
  hasWarning = false,
  onRemove,
  target,
  status,
  predicates
}) => {
  const { t } = useIntl()

  const name = useItemName(target)
  const isPlotTarget = isPlot(target)

  const targetURL = isPlotTarget
    ? `/farms/${target.organizationId}/plots/${target.id}`
    : isDevice(target)
      ? `/weather-map/${target.id}`
      : `/farms/${target.organizationId}/stations/${target.id}`

  const owner =
    isNil(target.networkName) && isNil(target.orgName)
      ? '-'
      : `${isNil(target.networkName) ? '' : `${target.networkName} / `}${target.orgName}`

  const TargetIcn = isPlotTarget ? Icons.PlotsFilled : Icons.SingleSensorFilled
  const isCumulGtGDD = !isNil(status) ? 'cumul-gt-GDD' in status : false
  const isCumulGtRR = !isNil(status) ? 'cumul-gt-RR' in status : false
  const isCumulGtLW_D = !isNil(status) ? 'cumul-gt-LW_D' in status : false

  return (
    <>
      {isShowingWarnings && (
        <TableCell>
          {hasWarning && (
            <TextTooltip content={t('alerts.incompatible_target_warning')}>
              <Icons.AlertTriangle $color={'feedback.warn.500'} $size='lg' />
            </TextTooltip>
          )}
        </TableCell>
      )}
      {isShowingStatuses && (
        <TableCell>
          <Box $width='fit-content'>
            {isCurrentlyTriggered ? (
              <Icons.Clock
                $rounded
                $size='lg'
                $backgroundColor={'feedback.error.500'}
                $color={'grayscale.white'}
                $p='sm'
              />
            ) : (
              <Icons.Close
                $rounded
                $backgroundColor={'grayscale.300'}
                $size='lg'
                $color={'grayscale.white'}
                $p='sm'
              />
            )}
          </Box>
        </TableCell>
      )}
      <TableCell>
        <Box $width='fit-content'>
          <TargetIcn $size='lg' />
        </Box>
      </TableCell>
      <TableCell $fontWeight={500}>
        <Box $mb='md'>
          {name}
          <Text $fontSize='sm'>{owner}</Text>
        </Box>
        {!isNil(status) && !isNil(predicates) ? (
          isCumulGtGDD ? (
            <CumulGtGDDProgress
              status={status['cumul-gt-GDD'] as PredicateStatus<'GDD', 'cumul-gt'>}
              predicate={predicates['cumul-gt-GDD'] as Predicate<'GDD', 'cumul-gt'>}
            />
          ) : isCumulGtRR ? (
            <CumulGtRRProgress
              status={status['cumul-gt-RR'] as PredicateStatus<'RR', 'cumul-gt'>}
              predicate={predicates['cumul-gt-RR'] as Predicate<'RR', 'cumul-gt'>}
            />
          ) : isCumulGtLW_D ? (
            <CumulGtLW_DProgress
              status={status['cumul-gt-LW_D'] as PredicateStatus<'LW_D', 'cumul-gt'>}
              predicate={predicates['cumul-gt-LW_D'] as Predicate<'LW_D', 'cumul-gt'>}
            />
          ) : null
        ) : null}
      </TableCell>
      {isAllowingEdition && (
        <TableCell>
          <Flex $alignItems='center' $justifyContent='flex-end'>
            <a href={targetURL}>
              <Icons.Preview $size='lg' />
            </a>
            <TextTooltip content={t('alerts.delete_target', { capitalize: true })}>
              <Icons.Bin $size='lg' onPress={onRemove} />
            </TextTooltip>
          </Flex>
        </TableCell>
      )}
    </>
  )
}

const keyExtractor = (tg: CompleteTargets) => tg.id.toString()
const EMPTY_ORGS: Org[] = []
const EMPTY_ALERT: Partial<Alert> = {}

interface AlertTargetTableProps
  extends Pick<TargetRowProps, 'isAllowingEdition' | 'isShowingStatuses'> {
  onTargetAddition?: (targets: TargetsWithTZ) => void
  onTargetDeletion?: (targets: TargetsWithTZ) => void
  targets: TargetsWithTZ | Alert['targets']
  sources?: Source[]
  alert?: Alert
}

const AlertTargetTable: FC<AlertTargetTableProps> = ({
  isAllowingEdition = true,
  isShowingStatuses = true,
  onTargetAddition,
  onTargetDeletion,
  targets: initialTargets,
  sources = [],
  alert
}) => {
  const { t } = useIntl()

  const { predicates, statuses } = alert ?? EMPTY_ALERT

  const { completeTargets, hasWarnings, isLoading } = useGetCompleteAlertTargets(
    initialTargets,
    sources
  )

  const targets = isTargetsWithTZ(initialTargets)
    ? initialTargets
    : convertTargetsToTargetsWithTz({
        targetsToConvert: initialTargets,
        completeTargets
      })

  const sortedTargets = useMemo(
    () =>
      isShowingStatuses && statuses
        ? completeTargets.sort((tA, tB) => {
            const isACurrentlyTriggered = getTargetTriggerStatus(tA, statuses)
            const isBCurrentlyTriggered = getTargetTriggerStatus(tB, statuses)

            if (
              (isACurrentlyTriggered && isBCurrentlyTriggered) ||
              (isACurrentlyTriggered && !isBCurrentlyTriggered)
            ) {
              return -1
            } else {
              return 1
            }
          })
        : completeTargets,
    [completeTargets, statuses, isShowingStatuses]
  )

  const removeFromTargets = useCallback(
    (target: Target) => {
      if (isPlot(target)) {
        const newPlots = targets.plots.filter(({ id }) => id !== target.id)

        onTargetDeletion?.({
          plots: newPlots,
          devices: targets.devices,
          stations: targets.stations
        })
      } else if (isDevice(target)) {
        const newDevices = targets.devices.filter(({ id }) => id !== target.id)

        onTargetDeletion?.({
          plots: targets.plots,
          devices: newDevices,
          stations: targets.stations
        })
      } else {
        const newStations = targets.stations.filter(({ id }) => id !== target.id)
        onTargetDeletion?.({
          plots: targets.plots,
          devices: targets.devices,
          stations: newStations
        })
      }
    },
    [targets, onTargetDeletion]
  )

  const columns = useMemo(() => {
    const results: TableColumn[] = []

    if (hasWarnings) {
      results.push({ label: '', width: `${WARNING_CELL_WIDTH}px` })
    }
    if (isShowingStatuses) {
      results.push({
        label: t('status.in_progress', { capitalize: true }),
        width: `${SMALLER_CELL_WIDTH}px`
      })
    }

    results.push(
      { label: t('alerts.target_type'), width: `${SMALLER_CELL_WIDTH}px` },
      { label: t('alerts.target'), width: 1 }
    )

    if (isAllowingEdition) results.push({ label: '', width: `${SMALLER_CELL_WIDTH}px` })

    return results
  }, [hasWarnings, isShowingStatuses, isAllowingEdition, t])

  const renderItem = useCallback(
    (tg: CompleteTargets) => {
      const isCurrentlyTriggered = !isNil(statuses) && getTargetTriggerStatus(tg, statuses)

      return (
        <TargetRow
          key={tg.id}
          target={tg}
          onRemove={() => removeFromTargets(tg)}
          isAllowingEdition={isAllowingEdition}
          isShowingStatuses={isShowingStatuses}
          isCurrentlyTriggered={isCurrentlyTriggered}
          hasWarning={tg.hasWarning}
          isShowingWarnings={hasWarnings}
          predicates={predicates}
          status={statuses?.[isPlot(tg) ? 'plots' : isDevice(tg) ? 'devices' : 'stations'][tg.id]}
        />
      )
    },
    [statuses, isAllowingEdition, isShowingStatuses, hasWarnings, predicates, removeFromTargets]
  )

  return (
    <Box>
      <Table
        outlined
        data={sortedTargets}
        renderRow={renderItem}
        keyExtractor={keyExtractor}
        tableName='alert-targets'
        columns={columns}
        emptyMessage={t('alerts.no_targets', { capitalize: true })}
        isLoading={isLoading}
      />
      {isAllowingEdition ? (
        <Flex $width='100%' $p='md' $alignItems='center' $justifyContent='center'>
          <Button
            importance='sd'
            onPress={() => onTargetAddition?.(targets)}
            IconLeft={Icons.PlusSign}
          >
            {t('alerts.add_more_targets', { capitalize: true })}
          </Button>
        </Flex>
      ) : null}
    </Box>
  )
}

export default AlertTargetTable
