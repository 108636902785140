import { defaultFeature, Features, Storer } from '@weenat/client'
import { CurrentTour, LightTourKey } from '@weenat/client/dist/core/tours'
import { Universe, UniverseAgg, UniverseMetric } from '@weenat/client/dist/core/universes'
import { HistoryState } from '@weenat/client/dist/HistoryContext'
import { MyFarmItem } from '@weenat/client/dist/resources/myFarm'
import { WeedgetKey } from '@weenat/client/dist/resources/weedgetPosition'
import { AbsoluteHref } from '@weenat/client/dist/routx/runtime-core'
import type { Locale } from '@weenat/wintl'
import { ReactNode, useEffect } from 'react'
import { z } from 'zod'
import { useSearchParams } from './routx-router'
import { detectBrowserLanguage } from './utils'

export const str = Storer({
  engine: {
    // eslint-disable-next-line @typescript-eslint/require-await
    async getItem(key: string) {
      return localStorage.getItem(key)
    },
    // eslint-disable-next-line @typescript-eslint/require-await
    async removeItem(key: string) {
      return localStorage.removeItem(key)
    },
    // eslint-disable-next-line @typescript-eslint/require-await
    async setItem(key: string, value: string) {
      return localStorage.setItem(key, value)
    }
  },
  migration: {
    version: JSON.stringify(defaultFeature) + '#1.0.57',
    keep: ['token', 'lightTour', 'clusterSettings']
  }
})

/** Auth */

export const [useToken, getToken] = str.create<string | null>(null, { persistKey: 'token' })

export const [useLoginAs, getLoginAs] = str.create<{ email: string; id: number } | null>(null, {
  persistKey: 'loginAs'
})

/** User preferences */

export type SelectedOrgs = {
  organizationId: number | null
  childOrganizationId: number | null
}

export const [useUserLanguage, getUserLanguage] = str.create<Locale>(detectBrowserLanguage())

export const [useOptionActivationTarget] = str.create<MyFarmItem | undefined>(undefined)

/** Feature Flags */

export const [useToggleFeature] = str.create<Features>(defaultFeature, { persistKey: 'features' })

export const flushAllButKeepConnected = () =>
  str.flush({ except: ['token', 'loginAs', 'features', 'lightTour'] })

/** History */

export const [useNewHistoryState] = str.create<Partial<HistoryState> | undefined>(undefined)

/** Tours */

export const [useCurrentTour] = str.create<CurrentTour>(undefined)
export const [useLightTours] = str.create<LightTourKey[]>([], { persistKey: 'lightTour' })

/** Universes */

const [useUniverseStore] = str.create<Universe>(Universe.weather, { persistKey: 'universe' })

/**
 * Hook that uses and sets the universe from the searchParams
 */

export const displaySettingsSearchParamsSchema = z
  .object({
    view: z.enum(['map', 'list']),
    universe: z.coerce.number().optional()
  })
  .partial()

export function useUniverse(reflectInSearchParams = true) {
  const [searchParams, setSearchParams] = useSearchParams(displaySettingsSearchParamsSchema)
  const [universeFromStore, setUniverseFromStore] = useUniverseStore()
  const universe: Universe = searchParams.universe ?? universeFromStore

  const setUniverse = (newValue: Universe) => {
    if ('universe' in searchParams || reflectInSearchParams) {
      setSearchParams({ ...searchParams, universe: newValue })
    }
    setUniverseFromStore(newValue)
  }

  useEffect(
    () => {
      if (universe !== universeFromStore) {
        setUniverseFromStore(universe)
      }
    },
    // We don't want setUniverseFromStore on deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [universe]
  )

  return [universe, setUniverse] as const
}

export const [useActiveMetricByUniverse] = str.create<Partial<
  Record<Universe, UniverseMetric>
> | null>(null, {
  persistKey: 'universeMetricByUniv'
})
export const useCurrentUnivMetric = () => {
  const [universe] = useUniverse()
  const [metricByUniverse] = useActiveMetricByUniverse()
  return metricByUniverse?.[universe]
}
export const [useUniverseAggregate] = str.create<UniverseAgg | null>(null, {
  persistKey: 'universeAgg'
})

export const [useUniverseHorizon] = str.create</** Horizon id */ number | null>(null)
export const [useAcquittedExternalErrors] = str.create<string[]>([], {
  persistKey: 'acquittedExternalErrors'
})

/** BREADCRUMBS STATES */

export interface Breadcrumb {
  path: AbsoluteHref
  label: string
}

export type BreadcrumbsContextKey =
  | 'user'
  | 'plot'
  | 'device'
  | 'option'
  | 'org'
  | 'adherentFarm'
  | 'alert'
  | 'station'
  | 'network'
  | 'section'
  | 'title'

export const [useBreadcrumbsCtx] = str.create<Partial<Record<BreadcrumbsContextKey, string>>>({})

/** Clusters */

interface ClusterSettings {
  maxZoom: number
  minPointsPerCluster: number
}

export const [useClusterSettings] = str.create<ClusterSettings | undefined>(undefined, {
  persistKey: 'clusterSettings'
})

/** Other */
export const [useSlotProvider] = str.create<
  Record<'headerActions' | 'mapTopControls', ReactNode[] | undefined>
>({ headerActions: undefined, mapTopControls: undefined })

/** PAYMENT */
interface PaymentCtx {
  from?: 'subscription' | 'onboarding' | 'invoicePayment'
  orgId?: number
}

export const [usePaymentCtx] = str.create<PaymentCtx>({}, { persistKey: 'paymentContext' })
export const [usePastSearches] = str.create<string[]>([], { persistKey: 'pastSearches' })
export type RecentlyOpenedElement = {
  isFavorite?: boolean
  name: string
  technicalName?: string
  organizationId: number
  reverseGeocoding: string | null
  id: number
  kind: 'station' | 'plot' | 'device'
}

export const [useRecentlyOpenedElements] = str.create<RecentlyOpenedElement[]>([], {
  persistKey: 'recentlyOpenedElement'
})

export const [useWeedgetPosition] = str.create<WeedgetKey[]>(
  [
    'weather_report',
    'cumulativeSumColdHour',
    'cumulativeSumHeatAccumulation',
    'cumulativeSumETP',
    'cumulativeSumLW_D',
    'cumulativeSumSSI',
    'maizy',
    'cropTracking',
    'weephyt',
    'add'
  ],
  { persistKey: 'weedget' }
)
