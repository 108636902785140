import { SimplifiedTarget } from '@weenat/client/dist/resources/alerts.types'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { memo } from 'react'
import { styled } from 'styled-components'
import Card from '../kit/Card'
import Checkbox from '../kit/Checkbox'
import Icons from '../kit/Icons'

const StyledCard = styled(Card)<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.md}px;

  padding: 8px;
  margin: 8px 0;

  border-radius: ${({ theme }) => theme.radiuses.md}px;
  border: 1px solid
    ${({ $isSelected, theme }) =>
      !$isSelected ? theme.colors.grayscale[300] : theme.colors.primary[500]};

  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.primary[200] : theme.colors.grayscale.white};

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.primary[500]};
  }
`

interface AlertTargetListItemProps {
  isSelected?: boolean
  targetName: string
  targetOrgName: string | undefined
  targetKind: 'plot' | 'device' | 'station'
  targetId: number
  targetTimezone: string
  handleTargetSelection: (target: SimplifiedTarget, isSelected: boolean) => void
}

const AlertTargetListItem: FC<AlertTargetListItemProps> = ({
  isSelected = false,
  targetName,
  targetOrgName,
  targetKind,
  targetId,
  targetTimezone,
  handleTargetSelection
}) => {
  const TargetIcn = targetKind === 'plot' ? Icons.PlotsFilled : Icons.SingleSensorFilled

  return (
    <StyledCard
      $isSelected={isSelected}
      onPress={() =>
        handleTargetSelection(
          {
            id: targetId,
            kind: targetKind,
            name: targetName,
            orgName: targetOrgName,
            timezone: targetTimezone
          },
          isSelected
        )
      }
    >
      <Checkbox checked={isSelected} />
      <Flex $alignItems='center' $gap='md'>
        <TargetIcn $size='lg' />
        <Flex $flexDirection='column'>
          <Text $fontWeight='bold'>{targetName}</Text>
          <Text $fontSize='sm'>{!isNil(targetOrgName) ? targetOrgName : '-'}</Text>
        </Flex>
      </Flex>
    </StyledCard>
  )
}

export default memo(AlertTargetListItem)
