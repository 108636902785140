import { fromColorPathToColor, WeenatColor } from '@weenat/theme'
import { isNil } from 'lodash-es'
import styled, { css } from 'styled-components'
import useDisclosure from '../hooks/useDisclosure'
import { Dropdown, DropdownContent, DropdownTrigger } from './fields/Select/SelectDropdown'
import Icons from './Icons'
import MenuItem from './MenuItem'
import Text from './Text'

const PressableFlex = styled(MenuItem)<{
  $backgroundColor: WeenatColor | undefined
}>`
  ${(p) =>
    !isNil(p.$backgroundColor)
      ? css`
          &:hover::after,
          &::after {
            background-color: ${fromColorPathToColor(p.$backgroundColor)};
          }
        `
      : null};
`

export interface DropdownSelectable<V extends string | number> {
  label: string
  color?: WeenatColor
  backgroundColor?: WeenatColor
  borderColor?: WeenatColor
  icon?: typeof Icons.AccountSettings
  value: V
}

export interface DropdownSelectProps<V extends string | number> {
  value: DropdownSelectable<V>['value'] | undefined
  options: DropdownSelectable<V>[]
  onChange: (newValue: DropdownSelectable<V>['value']) => void
  icon?: typeof Icons.AccountSettings
  className?: string
  borderColor?: WeenatColor
}

function DropdownSelect<T extends string | number>({
  value,
  options,
  onChange,
  icon,
  className,
  borderColor: propsBorderColor
}: DropdownSelectProps<T>) {
  const { isOpen, toggle, close } = useDisclosure()

  const currentOption = options.find((option) => option.value === value)

  const backgroundColor = currentOption?.backgroundColor
  const currentOptionBorderColor = currentOption?.borderColor
  const color = currentOption?.color
  const Icon = currentOption?.icon ?? icon

  const borderColor = propsBorderColor ?? currentOptionBorderColor

  return (
    <Dropdown isVisible={isOpen} onVisibleChange={toggle}>
      <DropdownTrigger className={className} style={{ minWidth: 0 }}>
        <Box
          $borderRadius='md'
          $backgroundColor={backgroundColor}
          $borderColor={borderColor}
          $borderWidth={1}
          $p='md'
          onClick={toggle}
          $cursor='pointer'
        >
          <Flex $alignItems='center' $gap='md'>
            <Flex $alignItems='center' $gap={'sm'} $minWidth={0}>
              {!isNil(Icon) ? <Icon $color={color} $size='md' /> : null}
              <Text $color={color} $ellipsis $fontWeight='medium'>
                {currentOption?.label}
              </Text>
            </Flex>
            <Icons.ChevronLeft $rotate={-90} $size='md' $color={color} />
          </Flex>
        </Box>
      </DropdownTrigger>
      <DropdownContent>
        <Flex $flexDirection='column' $gap='sm' $py='sm' $minWidth={352}>
          {options.map((option) => {
            const CurrentOptionIcon = option.icon
            return (
              <PressableFlex
                key={option.value}
                onPress={() => {
                  if (option.value !== currentOption?.value) onChange(option.value)
                  close()
                }}
                $backgroundColor={
                  option.value === currentOption?.value ? option.backgroundColor : undefined
                }
              >
                <Flex $alignItems='center' $gap={'sm'} $p={'md'}>
                  {!isNil(CurrentOptionIcon) ? (
                    <CurrentOptionIcon
                      $color={option.value === currentOption?.value ? option.color : undefined}
                      $size='lg'
                    />
                  ) : null}
                  <Text
                    $color={option.value === currentOption?.value ? option.color : undefined}
                    $fontWeight='medium'
                  >
                    {option?.label}
                  </Text>
                </Flex>
              </PressableFlex>
            )
          })}
        </Flex>
      </DropdownContent>
    </Dropdown>
  )
}

export default DropdownSelect
