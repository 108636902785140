import isNil from 'lodash-es/isNil'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

const INPUT_HEIGHT = 42

export interface InputFieldBackgroundProps {
  $isFocused: boolean
  $displayedError?: ReactNode
  $redesign?: boolean
}
/* stylelint-disable */
const InputFieldBackground = styled.div<InputFieldBackgroundProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${INPUT_HEIGHT}px;
  padding-left: ${(p) => p.theme.spacings.md}px;
  padding-right: ${(p) => p.theme.spacings.md}px;
  background-color: ${(p) =>
    p.$redesign ? p.theme.colors.grayscale[50] : p.theme.colors.grayscale[100]};
  border-radius: ${(p) => p.theme.radiuses.md}px;
  /* stylelint-disable */

  border-width: 1px;
  border-style: solid;
  border-color: ${(p) =>
    p.$isFocused
      ? p.theme.colors.primary[500]
      : !isNil(p.$displayedError)
        ? p.theme.colors.feedback.error[500]
        : p.theme.colors.grayscale[300]};
  /* stylelint-enable */
  overflow: hidden;

  &:hover {
    cursor: pointer;
    border-color: ${(p) => p.theme.colors.primary[500]};
  }

  transition: border 0.2s ease-in-out;
  will-change: border;
`

export default InputFieldBackground
