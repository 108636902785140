import type { SVGProps } from 'react'

const SvgCloudCoverage = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props}>
    <path
      d='M14.259 5c-1.564 0-3.196.468-4.448 1.518-1.16.974-1.96 2.42-2.084 4.356h-.81C4.744 10.874 3 12.704 3 14.937 3 17.17 4.743 19 6.918 19h6.776c.073 0 .145-.002.216-.006l.029-.002.028.002c.097.004.194.006.292.006C17.993 19 21 15.855 21 12s-3.007-7-6.741-7m3.108 10.367a1.266 1.266 0 0 1-.93.383c-.364 0-.674-.128-.93-.383a1.266 1.266 0 0 1-.382-.93c0-.364.128-.674.383-.93.255-.254.565-.382.93-.382.364 0 .674.128.93.383.254.255.382.565.382.93 0 .364-.128.674-.383.93m-5.348-.01a.84.84 0 0 1-.613-.263.852.852 0 0 1-.262-.624c0-.24.087-.448.262-.623l4.44-4.44a.852.852 0 0 1 .624-.263c.24 0 .449.087.624.262a.84.84 0 0 1 .262.613.84.84 0 0 1-.262.612l-4.463 4.463a.84.84 0 0 1-.612.262m.973-4.365a1.266 1.266 0 0 1-.93.383c-.364 0-.674-.128-.93-.383a1.266 1.266 0 0 1-.382-.93c0-.364.128-.674.383-.93.255-.254.565-.382.93-.382.364 0 .674.128.93.383.254.255.382.565.382.93 0 .364-.128.674-.383.93'
      clipRule='evenodd'
    />
  </svg>
)

export default SvgCloudCoverage
