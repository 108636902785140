import { fromColorPathToColor } from '@weenat/theme'
import { isNil } from 'lodash-es'
import { css, styled } from 'styled-components'

const DelimitedBox = styled(Box)<{ $isDelimitedOnTop?: boolean }>`
  ${(p) =>
    p.$isDelimitedOnTop
      ? css`
          border-top: 1px solid
            ${!isNil(p.$borderColor)
              ? fromColorPathToColor(p.$borderColor)
              : p.theme.colors.grayscale[100]};
        `
      : css`
          border-bottom: 1px solid
            ${!isNil(p.$borderColor)
              ? fromColorPathToColor(p.$borderColor)
              : p.theme.colors.grayscale[100]};
        `}
`

export default DelimitedBox
