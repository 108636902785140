import { AllowedMetricsInHistory } from '@weenat/client/dist/core/history'
import { getUnits } from '@weenat/client/dist/core/units'
import { AllKey, MetricsAggregatesType } from '@weenat/client/dist/enums/MetricIds'
import { isMetricWithUnit } from '@weenat/client/dist/enums/UnitChoices'
import { DataByMetric, NormalizerByUnit } from '@weenat/client/dist/resources/charts'
import React, { memo } from 'react'
import Area from './Area'
import Line, { LineProps } from './Line'

export type LineWithAggregatesProps = Pick<LineProps, 'timeStep'> & {
  metric: AllowedMetricsInHistory | MetricsAggregatesType
  dataByMetric: DataByMetric | undefined
  normalizerByUnit: NormalizerByUnit | undefined
  minSuffix?: 'N' | '-min'
  maxSuffix?: 'X' | '-max' | 'FXY'
}

const LineWithAggregates: React.FC<LineWithAggregatesProps> = ({
  metric,
  timeStep,
  dataByMetric,
  normalizerByUnit,
  maxSuffix = 'X' as const,
  minSuffix = 'N' as const
}) => {
  const metricData = dataByMetric?.get(metric)
  const metricDataMax = dataByMetric?.get(
    `${maxSuffix === 'FXY' ? 'FXY' : `${metric}${maxSuffix}`}` as 'TX'
  )

  const metricDataMin = dataByMetric?.get(`${metric}${minSuffix}` as 'TN')
  const metricScale = isMetricWithUnit(metric)
    ? normalizerByUnit?.get(getUnits(metric)[0])?.scale
    : undefined
  return metricData && metricScale ? (
    <>
      <Line data={metricData} timeStep={timeStep} scale={metricScale} metric={metric as AllKey} />
      {metricDataMax ? (
        <Area
          data={metricDataMax}
          y0Data={metricDataMin}
          metric={metric as AllKey}
          timeStep={timeStep}
          scale={metricScale}
        />
      ) : null}
    </>
  ) : null
}

export default memo(LineWithAggregates)
