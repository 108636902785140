import { RouterProvider } from 'app/routx-router'
import { createRoot } from 'react-dom/client'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import * as RR from 'react-router-dom'
import { routesConfig } from 'router-config'
import 'urlpattern-polyfill'
//pverride previous css
import './grid-layout-css-override.css'
import './i18n/setup'
import initFirebase from './utils/firebase'
import { initSentry } from './utils/sentry'

initSentry()
initFirebase()

const rootEl = document.getElementById('root')
const router = RR.createBrowserRouter(routesConfig)

if (rootEl) {
  const root = createRoot(rootEl)
  root.render(<RouterProvider router={router} />)
}
