import {
  FloatingFocusManager,
  FloatingList,
  FloatingOverlay,
  FloatingPortal,
  Placement,
  UseRoleProps,
  autoUpdate,
  flip,
  offset,
  size,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useListNavigation,
  useMergeRefs,
  useRole
} from '@floating-ui/react'
import isNil from 'lodash-es/isNil'
import {
  HTMLProps,
  ReactNode,
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import { styled } from 'styled-components'

interface DropdownOptions {
  isVisible: boolean
  onVisibleChange: (isVisible: boolean) => void
  marginFromParent?: number
  placement?: Placement
  /** default to true */
  followTriggerElement?: boolean
  /** default to listbox */
  role?: UseRoleProps['role']
}

function useDropdown({
  placement = 'bottom-start',
  marginFromParent = 8,
  isVisible,
  onVisibleChange,
  followTriggerElement = true,
  role: roleOption = 'listbox'
}: DropdownOptions) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const data = useFloating({
    placement,
    open: isVisible,
    onOpenChange: onVisibleChange,
    whileElementsMounted: followTriggerElement ? autoUpdate : undefined,
    middleware: [
      offset(marginFromParent),
      flip({ padding: 8 }),
      size({
        apply({ elements, availableHeight, availableWidth }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            maxWidth: `${availableWidth}px`
          })
        },
        padding: 8
      })
    ]
  })

  const elementsRef = useRef<Array<HTMLElement | null>>([])

  const focus = useFocus(data.context)
  const dismiss = useDismiss(data.context)

  const listNav = useListNavigation(data.context, {
    listRef: elementsRef,
    activeIndex,
    onNavigate: setActiveIndex,
    loop: true
  })

  const role = useRole(data.context, { role: roleOption })

  const interactions = useInteractions([focus, dismiss, role, listNav])

  return useMemo(
    () => ({
      isVisible,
      onVisibleChange,
      elementsRef,
      interactions,
      data,
      activeIndex
    }),
    [isVisible, onVisibleChange, interactions, data, activeIndex]
  )
}

const HeaderContainer = styled.div`
  position: sticky;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
`

const MIN_DROPDOWN_WIDTH = 128
const SELECT_DROPDOWN_Z_INDEX = 998

interface ContainerProps {
  $isVisible: boolean
  $width: string | number
}

const Container = styled.div<ContainerProps>`
  z-index: ${SELECT_DROPDOWN_Z_INDEX};

  width: ${(props) => (typeof props.$width === 'number' ? `${props.$width}px` : props.$width)};
  min-width: ${MIN_DROPDOWN_WIDTH}px;

  overflow-y: auto;
  background: ${(props) => props.theme.colors.grayscale.white};

  border: 1px solid ${(props) => props.theme.colors.grayscale[300]};
  border-radius: ${(p) => p.theme.radiuses.md}px;

  box-shadow: ${(p) => p.theme.shadows.sm.boxShadow};

  ${(props) =>
    !isNil(props.$isVisible) &&
    `
      opacity: ${props.$isVisible ? '1' : '0'}
    `};

  will-change: opacity, top;
`

type ContextType = ReturnType<typeof useDropdown> | null

const DropdownContext = createContext<ContextType>(null)

export const useDropdownContext = () => {
  const context = useContext(DropdownContext)

  if (context == null) {
    throw new Error('Dropdown components must be wrapped in <Dropdown />')
  }

  return context
}

export function Dropdown({ children, ...options }: { children: ReactNode } & DropdownOptions) {
  const dropdown = useDropdown(options)

  return <DropdownContext.Provider value={dropdown}>{children}</DropdownContext.Provider>
}

export const DropdownTrigger = forwardRef<
  HTMLElement,
  HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
  const { interactions, data, isVisible } = useDropdownContext()
  const childrenRef = children?.ref

  const ref = useMergeRefs([data.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      interactions.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': isVisible ? 'open' : 'closed',
        onClick: undefined
      })
    )
  }

  return (
    <div
      ref={ref}
      data-state={isVisible ? 'open' : 'closed'}
      className={props.className}
      {...interactions.getReferenceProps(props)}
    >
      {children}
    </div>
  )
})

const FLOATING_OVERLAY_STYLE = { zIndex: SELECT_DROPDOWN_Z_INDEX }

interface DropdownContentProps {
  width?: number
  Header?: ReactNode
  Footer?: ReactNode
  children: ReactNode
}

export function DropdownContent({ children, Footer, Header, width }: DropdownContentProps) {
  const { isVisible, data, interactions, elementsRef } = useDropdownContext()
  if (!isVisible) {
    return null
  }
  const openFromModal =
    (data.refs.reference.current as HTMLDivElement | null)?.closest('.weenat-modal') !== null
  const floatingUiRoot = document.getElementById('floating-ui')
  floatingUiRoot?.setAttribute(
    'style',
    //if floating element is open from a modal we want to show it over the modal (select dropdown inside modal)
    // otherwise it should be render under modal so that modal is still clickable
    `z-index: ${openFromModal ? 1000 : 900}; position: relative`
  )

  return (
    <FloatingPortal root={floatingUiRoot}>
      <FloatingOverlay lockScroll style={FLOATING_OVERLAY_STYLE}>
        <FloatingFocusManager context={data.context} modal>
          <Container
            ref={data.refs.setFloating}
            style={data.floatingStyles}
            $isVisible={isVisible}
            $width={width}
            {...interactions.getFloatingProps()}
          >
            {!isNil(Header) ? <HeaderContainer>{Header}</HeaderContainer> : null}
            <FloatingList elementsRef={elementsRef}>{children}</FloatingList>
            {Footer}
          </Container>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  )
}
