import { Locale, defaultLanguage, useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { memo } from 'react'
import { styled } from 'styled-components'
import Link from './LinkComponent'
import FullScreenSurface from './fullscreens/FullScreenSurface'
import { LogoFullLight } from './icons/logos'

const APP_STORE_BADGE_LOCALES: Record<Locale, string> = {
  en: 'en-us',
  fr: 'fr-fr',
  de: 'de-de',
  it: 'it-it',
  es: 'es-es',
  pl: 'pl-pl',
  pt: 'pt-pt'
}

const STORE_BUTTON_WIDTH = 196
const STORE_BUTTON_HEIGHT = 96

const StyledText = styled(Text)`
  text-align: center;
  max-width: 640px;
`

const ContentContainer = styled(Flex)`
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  padding: 64px;

  overflow-y: scroll;
`

const GradientSurface = styled(FullScreenSurface)`
  background: linear-gradient(223deg, #002635 5%, #015579 50%, #0172a4 100%);
`

const DownloadMobileScreen = () => {
  const { t, locales } = useIntl()
  const locale = locales.current

  return (
    <GradientSurface>
      <ContentContainer>
        <Flex $flexDirection='column' $alignItems='center' $justifyContent='center' $gap='xl'>
          <Link href={t('weenat.links.website')} target='_blank'>
            <LogoFullLight width={'100%'} height={64} />
          </Link>
          <StyledText $color={'grayscale.white'} $fontSize='2xl' $fontWeight='regular'>
            {t('titles.install_app_title', {
              live: t('titles.install_app_title_live')
            })}
          </StyledText>
          <StyledText $color={'grayscale.white'} $fontSize='lg' $textAlign='center'>
            {t('titles.install_app_subtitle')}
          </StyledText>
          <Flex $alignItems='center' $gap='md' $flexWrap='wrap' $justifyContent='center'>
            <a href={t('weenat.links.stores.google')} style={{ display: 'inline-block' }}>
              <img
                src={`https://play.google.com/intl/en_us/badges/static/images/badges/${locale || defaultLanguage}_badge_web_generic.png`}
                alt='Download on the play Store'
                style={{
                  /* there is margin included in android image so we have to cheat here to keep both img the same size*/
                  width: STORE_BUTTON_WIDTH + 14,
                  height: STORE_BUTTON_HEIGHT,
                  verticalAlign: 'middle',
                  objectFit: 'contain'
                }}
              />
            </a>
            <a href={t('weenat.links.stores.apple')} style={{ display: 'inline-block' }}>
              <img
                src={`https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/${APP_STORE_BADGE_LOCALES[locale || defaultLanguage]}?releaseDate=1591920000`}
                alt='Download on the App Store'
                style={{
                  width: STORE_BUTTON_WIDTH,
                  height: STORE_BUTTON_HEIGHT,
                  verticalAlign: 'middle',
                  objectFit: 'contain'
                }}
              />
            </a>
          </Flex>
        </Flex>
      </ContentContainer>
    </GradientSurface>
  )
}

export default memo(DownloadMobileScreen)
