import { useChartCtx } from '@weenat/client/dist/core/charts'
import { memo } from 'react'

function FutureArea() {
  const { xScale, marginTop, marginLeft, marginRight, height, width, marginBottom } = useChartCtx()

  const xRange = xScale.range()

  const now = Date.now()
  const nowInPx = xScale(new Date(now))

  const displayArea = nowInPx < xRange[1]

  if (!displayArea) return null

  const startOfArea = nowInPx > 0 ? nowInPx : marginLeft

  const rectWidth = width - marginRight - startOfArea
  const rectHeight = height - marginBottom - marginTop

  const transform = `translate(${startOfArea + 8},${marginTop})`

  return (
    <g transform={transform}>
      {/* Tiling using a square with 3 strokes inside */}
      <pattern id='diagonalHatch' patternUnits='userSpaceOnUse' width='16' height='16'>
        <path
          stroke='#000'
          strokeWidth={4}
          strokeOpacity={0.1}
          d='M-4,4 l8,-8
           M0,16 l16,-16
           M12,20 l8,-8'
        />
      </pattern>
      <rect x='0' y='0' width={rectWidth} height={rectHeight} fill='url(#diagonalHatch)' />
    </g>
  )
}

export default memo(FutureArea)
