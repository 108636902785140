import { fromColorPathToColor } from '@weenat/theme'
import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { transparentize } from 'polished'
import { styled } from 'styled-components'
import Button from './Button'
import CloseButton from './CloseButton'
import Icon from './Icon'
import Icons from './Icons'

const CTAHolder = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 16px 0 8px;
`

interface CTACardProps {
  title: string
  description?: string
  cta?: string
  ctaIcon?: typeof Icon
  link?: string
  onDiscard?: () => void
  onCTAPress?: () => void
  textAlign?: 'center' | 'left'
  className?: string
}

const CTACard: FC<CTACardProps> = ({
  title,
  cta,
  ctaIcon = null,
  link,
  children,
  onDiscard,
  onCTAPress,
  description,
  textAlign = 'center',
  className
}) => {
  const { t } = useIntl()

  const textColor = 'grayscale.white'

  return (
    <Box $borderRadius='lg' $p='lg' $my='lg' className={className} $backgroundColor={'primary.500'}>
      <Flex $alignItems='center' $justifyContent='flex-end' $mb='md'>
        {!isNil(onDiscard) ? (
          <CloseButton
            $backgroundColor={transparentize(0.8, fromColorPathToColor(textColor))}
            $color={textColor}
            onPress={onDiscard}
          />
        ) : null}
      </Flex>
      <Text
        $fontSize='md'
        $fontWeight='bold'
        $lineHeight='lg'
        $textAlign={textAlign}
        $color={textColor}
      >
        {title}
      </Text>
      {!isNil(description) ? (
        <Text $fontSize='rg' $textAlign={textAlign} $color={textColor}>
          {description}
        </Text>
      ) : null}
      <CTAHolder>
        {!isNil(link) || !isNil(onCTAPress) ? (
          <Button
            href={link}
            backgroundColor={textColor}
            color={'primary.500'}
            onPress={onCTAPress}
            IconRight={!isNil(ctaIcon) ? ctaIcon : Icons.ArrowRight}
          >
            {!isNil(cta) ? cta : t('actions.learn_more')}
          </Button>
        ) : null}
      </CTAHolder>
      {children}
    </Box>
  )
}

export default CTACard
