import { useItemContext } from '@weenat/client'
import { getTimeDomain } from '@weenat/client/dist/core/charts'
import { DataPoint } from '@weenat/client/dist/core/charts/d3-charts.type'
import { useFrostMetricsSummaryChart } from '@weenat/client/dist/core/metrics'
import TimeSteps from '@weenat/client/dist/enums/TimeSteps'
import { useConvertedValue } from '@weenat/client/dist/hooks'
import { Target } from '@weenat/client/dist/resources/charts'
import D3Axis from 'app/src/kit/d3-charts/Axis'
import CursorChart from 'app/src/kit/d3-charts/CursorChart'
import D3TimeAxis from 'app/src/kit/d3-charts/TimeAxis'
import { isNil } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import moment from 'moment-timezone'
import { useCallback, useMemo } from 'react'
import { styled } from 'styled-components'
import Text from '../../Text'
import D3ChartWrapper from '../../d3-charts/D3ChartWrapper'
import FutureArea from '../../d3-charts/FutureArea'
import LineWithAggregates from '../../d3-charts/LineWithAggregates'

const CHART_HEIGHT = 148

const AbsolutelyPostionedContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: -16px;
  height: ${CHART_HEIGHT}px;
  width: 100%;
`

/**
 * Chart used inside summaries
 */
const FrostMetricsSummaryChart: FC<{
  target: Target
}> = ({ target }) => {
  const { timezone } = useItemContext()
  const { convertValue } = useConvertedValue()

  const frostMetricChart = useFrostMetricsSummaryChart(target)

  const nonNullDataPoints = frostMetricChart.data?.data.filter((data) => data.value !== null)

  const renderTooltip = useCallback(
    ({ date }: DataPoint) => {
      const tWetValue = frostMetricChart.data?.dataByDateByMetric
        .get(date.getTime())
        ?.get('T_WET')?.value

      const tDryValue = frostMetricChart.data?.dataByDateByMetric
        .get(date.getTime())
        ?.get('T_DRY')?.value
      return (
        <Box
          $backgroundColor='grayscale.white'
          $borderColor='grayscale.200'
          $borderRadius='sm'
          $borderWidth='rg'
          $p='md'
          $pointerEvents='none'
        >
          <Text>{moment(date).format('DD/MM HH:mm')}</Text>
          {!isNil(frostMetricChart.data?.dataByDateByMetric) ? (
            <>
              <Text $color={`metrics.T_DRY.500`}>
                {convertValue({
                  metric: 'T_DRY',
                  value: tDryValue,
                  showEmptyState: true
                })}
              </Text>
              <Text $color={`metrics.T_WET.500`}>
                {convertValue({
                  metric: 'T_WET',
                  value: tWetValue,
                  showEmptyState: true
                })}
              </Text>
            </>
          ) : null}
        </Box>
      )
    },
    [convertValue, frostMetricChart.data?.dataByDateByMetric]
  )

  const allMetricsDataPoints = frostMetricChart.data?.data

  const chartDomain = useMemo(
    () =>
      allMetricsDataPoints !== undefined && !isEmpty(allMetricsDataPoints)
        ? {
            x: getTimeDomain(allMetricsDataPoints),
            y: [0, 1] as [number, number]
          }
        : undefined,
    [allMetricsDataPoints]
  )

  return (
    <Box $position='relative' $height={CHART_HEIGHT}>
      <AbsolutelyPostionedContainer>
        <D3ChartWrapper
          height={CHART_HEIGHT}
          isEmpty={isEmpty(nonNullDataPoints)}
          isLoading={frostMetricChart.isLoading}
        >
          {!isNil(allMetricsDataPoints) && !isNil(frostMetricChart.data) && !isNil(chartDomain) ? (
            <CursorChart
              domain={chartDomain}
              width='100%'
              height={CHART_HEIGHT}
              data={allMetricsDataPoints}
              renderTooltip={renderTooltip}
              domainPadding={{ bottom: 0, left: 16, right: 16, top: 16 }}
              ticksCount={3}
              timeStep={TimeSteps.perHours}
            >
              <FutureArea />
              <LineWithAggregates
                dataByMetric={frostMetricChart.data.dataByMetric}
                metric='T_DRY'
                normalizerByUnit={frostMetricChart.data.normalizerByUnit}
                timeStep={TimeSteps.perHours}
                minSuffix='-min'
                maxSuffix='-max'
              />
              <LineWithAggregates
                dataByMetric={frostMetricChart.data.dataByMetric}
                metric='T_WET'
                normalizerByUnit={frostMetricChart.data.normalizerByUnit}
                timeStep={TimeSteps.perHours}
                minSuffix='-min'
                maxSuffix='-max'
              />
              <D3TimeAxis timezone={timezone} period={chartDomain.x} isFourHourTimeStep={false} />
              <D3Axis
                denormalize={frostMetricChart.data?.normalizerByUnit
                  .get('degrees_celsius')
                  ?.scale.invert.bind(this)}
                metric='T_DRY'
              />
            </CursorChart>
          ) : null}
        </D3ChartWrapper>
      </AbsolutelyPostionedContainer>
    </Box>
  )
}

export default FrostMetricsSummaryChart
