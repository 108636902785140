import { useIntl } from '@weenat/wintl'
import CTACard from 'app/src/kit/CTACard'
import logEvent from 'app/utils/analytics'
import { memo } from 'react'

const NeedHelp = () => {
  const { t } = useIntl()
  return (
    <CTACard
      title={t('help.need_help')}
      cta={t('help.consult_faq', { capitalize: true })}
      onCTAPress={() => {
        logEvent('open_help_centre')
        window.open(t('weenat.help.application.generalUsage.faq'), '_blank')
      }}
    />
  )
}

export default memo(NeedHelp)
