import { enums, queryClient, superscheme, useClient, validators as v } from '@weenat/client'
import { OrgRole } from '@weenat/client/dist/enums'
import { AbsoluteHref } from '@weenat/client/dist/routx/runtime-core'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useMatch, useNavigate, useParams, usePathname } from 'app/routx-router'
import useSelectOrg, { UseSelectOrgArgs } from 'app/src/hooks/useSelectOrg'
import SuperForm from 'app/src/kit/SuperForm'
import SelectField from 'app/src/kit/fields/SelectField'
import UseOnChange from 'app/src/kit/fields/UseOnChange'
import { LogoSphere } from 'app/src/kit/icons/logos'
import { SelectedOrgs } from 'app/state'
import logEvent from 'app/utils/analytics'
import { FormikContextType } from 'formik'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useState } from 'react'
import { styled } from 'styled-components'

// TODO: Add null as a possibility for childOrganizationId
const orgSelectionSchema = superscheme({
  organizationId: v.number,
  childOrganizationId: v.optionalNumber
})

type SelectOrgFieldsProps = UseSelectOrgArgs & {
  orgFieldName?: string
  childOrgFieldName?: string
  setSelectedOrgs?: React.Dispatch<React.SetStateAction<SelectedOrgs | undefined>>
}

const NetworkLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

/**
 * Render only the fields without a form wrapper
 */
export const SelectOrgFields = ({
  selectedOrgs,
  withoutChild,
  orgFieldName = 'organizationId',
  childOrgFieldName = 'childOrganizationId'
}: SelectOrgFieldsProps) => {
  const { t } = useIntl()

  const {
    network,
    org,
    selectOrgLabel,
    selectChildOrgLabel,
    orgOptions,
    childOrgOptions,
    selectedOrgs: selectedOrgsFromHook,
    isOrgNetwork,
    orgsRequest,
    isLoading: networkOrgIsLoading,
    setEnableNetworkOrgRequest
  } = useSelectOrg({
    selectedOrgs
  })

  return (
    <>
      <SelectField
        leftAdornment={
          !isNil(network) ? (
            <Box $height='100%' $maxWidth={64}>
              {isNil(network?.logo) ? (
                <LogoSphere height='100%' width={24} />
              ) : (
                <NetworkLogo src={network.logo} />
              )}
            </Box>
          ) : null
        }
        placeholder={
          !isNil(selectedOrgsFromHook?.organizationId)
            ? undefined
            : t('models.organization.model.no_items')
        }
        name={orgFieldName}
        isDisabled={orgsRequest.isLoading}
        options={orgOptions}
        label={selectOrgLabel}
      />
      {!withoutChild &&
      isOrgNetwork &&
      org?.myRole !== OrgRole.viewer &&
      !isEmpty(childOrgOptions) ? (
        <SelectField
          isAutocomplete
          name={childOrgFieldName}
          label={selectChildOrgLabel}
          options={childOrgOptions}
          isDisabled={networkOrgIsLoading}
          isLoading={networkOrgIsLoading}
          placeholder={
            !isNil(selectedOrgsFromHook?.childOrganizationId) ? undefined : t('map.network_plots')
          }
          onToggle={(isOpen) => setEnableNetworkOrgRequest(isOpen)}
        />
      ) : null}
    </>
  )
}

const SelectOrg = () => {
  const nav = useNavigate()
  const org = useOrgContext()
  const pathname = usePathname()
  const { farmId } = useParams()
  const client = useClient()

  const isAffiliateFarm = !isNil(org.subscribedNetworkId) && org.myRole !== OrgRole.owner

  const [selectedOrgs, setSelectedOrgs] = useState<SelectedOrgs>()

  if (isAffiliateFarm && org.myRole !== enums.OrgRole.viewer) {
    if (
      selectedOrgs?.organizationId !== org.subscribedNetworkId ||
      selectedOrgs.childOrganizationId !== org.id
    ) {
      setSelectedOrgs({ organizationId: org.subscribedNetworkId, childOrganizationId: org.id })
    }
  } else {
    if (selectedOrgs?.organizationId !== org.id || selectedOrgs.childOrganizationId !== null) {
      setSelectedOrgs({ organizationId: org.id, childOrganizationId: null })
    }
  }

  const matchAdminPath = useMatch('/farms/*/admin/*')
  const isAdmin = !isNil(matchAdminPath)

  const { selectedOrgs: selectedOrgsFromHook } = useSelectOrg({
    selectedOrgs
  })

  const handleChange = async (
    formValues: {
      organizationId: number
      childOrganizationId?: number | null
    },
    ctxContext: FormikContextType<typeof orgSelectionSchema.initialValues>
  ) => {
    let newOrgId: number | undefined
    //user has changed org or network select
    if (formValues.organizationId !== selectedOrgsFromHook?.organizationId) {
      newOrgId = formValues.organizationId
      // user has changed affiliate farm
    } else if (formValues.childOrganizationId !== selectedOrgsFromHook.childOrganizationId) {
      // user has selected farms from network
      if (isNil(formValues.childOrganizationId)) {
        newOrgId = formValues.organizationId
      } else {
        // user has selected affiliate farm
        newOrgId = formValues.childOrganizationId
      }
    }

    if (formValues.organizationId === selectedOrgsFromHook?.organizationId)
      logEvent('change_child_organisation')

    if (!isNil(newOrgId)) {
      const requester = client.orgs.get(newOrgId)

      await queryClient.prefetchQuery({
        queryKey: requester.key,
        queryFn: requester.request.bind(this)
      })

      // Smart redirects on administration
      // only redirect if forms has been modified by user
      if (ctxContext.dirty) {
        if (isAdmin && !isNil(farmId)) {
          // Looking for segments with number to only redirect if we are on a details page
          const hasMoreThanTwoNumberSegments =
            pathname.split('/').filter((segment) => !Number.isNaN(parseInt(segment, 10))).length > 1

          nav(
            hasMoreThanTwoNumberSegments
              ? `/farms/${newOrgId}/admin`
              : (pathname.replace(farmId.toString(), newOrgId.toString()) as AbsoluteHref)
          )
        }
        // Always redirecting to the farms home page on org change
        else {
          nav(`/farms/${newOrgId}`)
        }
      }
    }
  }

  const initialValues = selectedOrgs

  if (!isNil(selectedOrgsFromHook?.organizationId)) {
    return (
      <Flex $flexDirection='column'>
        <SuperForm schema={orgSelectionSchema} enableReinitialize initialValues={initialValues}>
          <SelectOrgFields selectedOrgs={selectedOrgs} />
          <UseOnChange onChange={handleChange} />
        </SuperForm>
      </Flex>
    )
  }
  return null
}

export default SelectOrg
