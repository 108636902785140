import { useToggleFeature } from 'app/state'
import { IconProps } from '../Icon'
import Icons from '../Icons'

interface WindDirectionIconProps extends Omit<IconProps, 'rotate'> {
  windOrigin: number
}

const WindDirectionIcon: React.FC<WindDirectionIconProps> = ({ windOrigin, ...rest }) => {
  const [{ wr_forecasts }] = useToggleFeature()

  const Icn = wr_forecasts ? Icons.ArrowUp : Icons.Navigation

  return <Icn $rotate={windOrigin + 180} {...rest} />
}

export default WindDirectionIcon
