import { usePathname } from 'app/routx-router'
import isNil from 'lodash-es/isNil'
import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { css, styled } from 'styled-components'
import { BoxProps } from './primitives/themeMappings/props'

interface ScrollableProps extends BoxProps {
  /** Whether or not to hide the scrollbar */
  $isDiscrete?: boolean
  /** Type of scroll to apply */
  $scrollBehavior?: CSSProperties['scrollBehavior']
  /** Whether or not to scroll to the top of the div when the route changes */
  scrollToTopOnRouteChange?: boolean
  /** Dependency array. This will scroll to the top when one of the given elements of this array changes */
  scrollToTopOnNew?: unknown[]
}

const ScrollableContainer = styled(Box)<{
  $scrollBehavior: ScrollableProps['$scrollBehavior']
  $isDiscrete: ScrollableProps['$isDiscrete']
}>`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  scroll-behavior: ${(props) => props.$scrollBehavior};
  scrollbar-gutter: stable;

  ${(props) =>
    props.$isDiscrete &&
    css`
      /** Hiding scrollbar but keeping scroll */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      /** Chromium based */
      &::-webkit-scrollbar {
        display: none;
      }
    `}

  &:hover {
    overflow-y: scroll;
  }
`

const Scrollable = forwardRef<HTMLDivElement, ScrollableProps>(
  (
    {
      $scrollBehavior = 'smooth',
      scrollToTopOnRouteChange = true,
      scrollToTopOnNew,
      $isDiscrete = false,
      ...props
    },
    ref
  ) => {
    const innerRef = useRef<HTMLDivElement>(null)
    const pathname = usePathname()

    useImperativeHandle(ref, () => innerRef.current)

    // scroll to top on route change
    useEffect(() => {
      if (scrollToTopOnRouteChange && !isNil(innerRef.current)) {
        innerRef.current.scrollTop = 0
      }
    }, [pathname, scrollToTopOnRouteChange])

    // scroll to top on deps change
    useEffect(() => {
      if (innerRef.current) {
        innerRef.current.scrollTop = 0
      }
    }, [scrollToTopOnNew])

    return (
      <ScrollableContainer
        ref={innerRef}
        $scrollBehavior={$scrollBehavior}
        $isDiscrete={$isDiscrete}
        {...props}
      />
    )
  }
)

export default Scrollable
